import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import icons from '../assets/icons'
import { SVG, Text, Button, InputField, Image } from '../components/UI'
import { useStore, useAwakeBool, useRouter, usePopupPanel } from '../hooks'
import { Dictionary, ICategory, IExperience } from '../store'
import { pathFunctions } from '../utility'

import { SLayout, STitle, SSearch, SGrid, SGridHolder, EditButtons } from './'

import EditExperience from '../panels/EditExperience'
import Confirmation from '../panels/Confirmation'

import { Paths } from '../context/RouterContext'
import EditCategory from '../panels/EditCategory'

import { arrayMove } from 'react-sortable-hoc'
import SortableList from '../components/SorteableList'
import { exit } from 'process'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


interface IHomeState {
    selected?: ICategory
}

export default function Home() {
    const [state, set] = useState<IHomeState>({})
    const awake = useAwakeBool()
    const store = useStore()
    const popupPanel = usePopupPanel()

    useEffect(() => {
        if (store.state?.categories && !state.selected)
            set(p => ({ ...p, selected: Object.values(store.state?.categories).sort((a, b) => a.order > b.order ? 1 : -1)[0] }))
    }, [store.state?.categories])

    // Get selected category's experiences, sort them and map them to a new dictionary.
    function getSelectedExperiences(): Dictionary<IExperience> {
        try {
            return Object.entries(state.selected.experiences)
                .sort(([aK, oA], [bK, oB]) => oA > oB ? 1 : -1)
                .map(([k, o]) => store.state.experiences[k])
                .reduce((a, x) => ({ ...a, [x.id]: x }), {}) as Dictionary<IExperience>

        } catch { }
    }

    // Open experience editor panel for a new experience.
    function newExperienceHandler(): void {
        popupPanel.setContent(
            <EditExperience onSave={experience => {
                if (!state?.selected?.experiences) state.selected.experiences = {}
                state.selected.experiences[experience.id] = 1
                store.dispatch({ type: 'set-category', payload: { ...state.selected } })
                popupPanel.setActive(false)
            }} />
        )
        popupPanel.setActive(true)
    }

    // Open category editor panel for a new category.
    function newCategoryHandler(): void {
        popupPanel.setContent(
            <EditCategory
                onSave={category => {
                    // Close panel.
                    popupPanel.setActive(false)
                    // Select this new category.
                    set(p => ({ ...p, selected: category }))
                }}
            />
        )
        popupPanel.setActive(true)
    }

    return (
        <SLayout awake={awake}>
            <Categories newHandler={newCategoryHandler} categories={store.state?.categories} selected={state.selected} categoryClickHandler={c => set(p => ({ ...p, selected: c }))} />
            <Experiences experiences={getSelectedExperiences()} newHandler={newExperienceHandler} selectedCategory={state.selected} />
        </SLayout>
    )
}

const SCategories = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-rows: min-content min-content auto;
`

interface ICategoriesState {
    search: string,
}

function Categories(props: { categories: Dictionary<ICategory>, selected?: ICategory, categoryClickHandler(category: ICategory): void, newHandler(): void }) {
    const store = useStore()

    const [state, set] = useState<ICategoriesState>({
        search: '',
    })
    const [categoriesReorder, setCategoriesReorder] = useState([])
    const [categoryWasMoved, setCategoryWasMoved] = useState(false)

    function getCategories() {
        if (!props.categories) return

        return Object.entries(props.categories).filter(([key, value]) => {
            if (!state.search) return true
            return value.name.toLowerCase().includes(state.search.toLowerCase())
        })
        .sort(([keyA, a], [keyB, b]) => (a.order ?? Infinity) > (b.order ?? Infinity) ? 1 : -1)
        .map(([key, category]) => <CategoryTag
            clickHandler={() => props.categoryClickHandler(category)}
            selected={category.id === props?.selected?.id}
            key={key}
            category={category}
        />)
    }

    useEffect(() => {
        if (props.categories) setCategoriesReorder(getCategories())
    }, [props.categories])

    useEffect(() => {
        setCategoriesReorder(getCategories())
    }, [state.search, props.selected])

    useEffect(() => {
        if (categoryWasMoved) {
            for (let orderIndex in categoriesReorder) {
                categoriesReorder[orderIndex].props.category.order = parseInt(orderIndex)
                store.dispatch({ type: 'set-category', payload: categoriesReorder[orderIndex].props.category as ICategory })
            }

            toast("Changes saved successfully!")
            setCategoryWasMoved(false)
        }
    }, [categoryWasMoved])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (state.search != '') return
        setCategoriesReorder(arrayMove(categoriesReorder, oldIndex, newIndex))
        if (oldIndex !== newIndex) setCategoryWasMoved(true)
     }

    return (
        <SCategories>
            <STitle>
                <SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={icons.categories} contain dark />
                <Text middle bold big dark>Categories</Text>
                <Button onClick={() => props.newHandler()} icon={icons.add}>Add</Button>
            </STitle>
            <SSearch>
                <SVG style={{ width: '70%', height: '70%', margin: 'auto' }} contain primary image={icons.search} />
                <InputField
                    onChange={v => set(p => ({ ...p, search: v }))}
                    value={state.search}
                    placeholder='Search'
                />
            </SSearch>
            <SGridHolder >
                <SGrid>
                    <SortableList items={categoriesReorder} onSortEnd={onSortEnd} />
                </SGrid>
            </SGridHolder>
        </SCategories>
    )
}

const SCategoryTag = styled.div<{ selected?: boolean, awake?: boolean }>`
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.selected ? p.theme.colors.primary : p.theme.colors.light};
    height: 7rem;
    padding: .5rem;
    display: grid;
    grid-template-columns: 1fr 2fr min-content;
    grid-gap: ${p => p.theme.gridGap};
    transition: .3s;
    box-sizing: border-box;
    overflow: hidden;

    * {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ${p => p.selected && css`
        * {
            color: ${p => p.theme.colors.light};
            * {
                * { background-color: ${p => p.theme.colors.light}; }
            }
        }
    `}

    opacity: ${p => p.awake ? 1 : 0};
    ${p => !p.awake && css`
        transform: translateY(2rem);
    `}

    :hover {
        background-color: ${p => p.theme.colors.primaryLight};
        * {
            color: ${p => p.theme.colors.dark};
        }
    }
`

const SImage = styled.div`
    border-radius: ${p => p.theme.borderRadius};
    overflow: hidden;
    cursor: pointer;
`

const SCategoryText = styled.div`
    display: grid;
    grid-template-rows: auto min-content min-content auto;
    cursor: pointer;
`

function CategoryTag(props: { category: ICategory, selected: boolean, clickHandler }) {
    const awake = useAwakeBool()
    const popupPanel = usePopupPanel()
    const store = useStore()

    function getExperiencesCount(): number {
        try { return Object.values(props.category.experiences).length } catch { return 0 }
    }

    function editHandler(): void {
        popupPanel.setContent(
            <EditCategory
                category={props.category}
                onSave={category => {
                    popupPanel.setActive(false)
                }}
            />
        )
        popupPanel.setActive(true)
    }

    function deleteHandler(): void {
        popupPanel.setContent(
            <Confirmation
                title='Delete'
                icon={icons.delete}
                description={`Are you sure you want to delete ${props.category.name}?`}
                subDescription='This action cannot be undone.'
                cancel='Cancel'
                confirm='Delete'
                confirmHandler={() => {
                    // Delete every category's experience.
                    if (props.category.experiences) Object.keys(props.category.experiences).forEach(eKey => {
                        if (store?.state?.experiences[eKey]) store.dispatch({ type: 'delete-experience', payload: store.state.experiences[eKey] })
                    })
                    // Finally, delete category.
                    store.dispatch({ type: 'delete-category', payload: props.category })
                }}
            />
        )
        popupPanel.setActive(true)
    }

    const experiencesCount = getExperiencesCount()

    return (
        <SCategoryTag awake={awake} selected={props.selected}>
            <SImage onClick={() => props.clickHandler()}><Image url={pathFunctions.getCategoryThumbnail(props.category)} version={props.category.imageVersion}/></SImage>
            <SCategoryText onClick={() => props.clickHandler()}>
                <div />
                <Text bold oneline dark>{props.category.name}</Text>
                <Text oneline medium>{`${experiencesCount} Experience${experiencesCount != 1 ? 's' : ''}`}</Text>
                <div />
            </SCategoryText>
            <EditButtons
                editHandler={editHandler}
                deleteHandler={deleteHandler}
            />
        </SCategoryTag>
    )
}

const SExperienceTag = styled.div<{ awake?: boolean }>`
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.theme.colors.light};
    height: 6rem;
    padding: .5rem;
    display: grid;
    grid-template-columns: 6rem auto min-content;
    grid-gap: ${p => p.theme.gridGap};
    transition: .3s;
    box-sizing: border-box;
    overflow: hidden;

    * {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    opacity: ${p => p.awake ? 1 : 0};
    ${p => !p.awake && css`
        transform: translateY(2rem);
    `}

    :hover {
        background-color: ${p => p.theme.colors.primaryLight};
        * {
            color: ${p => p.theme.colors.dark};
        }
    }
`

function ExperienceTag(props: { experience: IExperience, selectedCategory: ICategory }) {
    const awake = useAwakeBool()
    const router = useRouter()
    const store = useStore()
    const popupPanel = usePopupPanel()

    function clickHandler(): void {
        router.setPath(Paths.experience)
        router.setParams(`category=${props.selectedCategory.id}&experience=${props.experience.id}`)
    }

    function editHandler(): void {
        popupPanel.setContent(<EditExperience
            onSave={(experience => {
                store.dispatch({ type: 'set-experience', payload: experience })
                popupPanel.setActive(false)
            })}
            experience={props.experience}
        />)
        popupPanel.setActive(true)
    }

    function deleteHandler(): void {
        popupPanel.setContent(<Confirmation
            title='Delete'
            icon={icons.delete}
            description={`Are you sure you want to delete ${props.experience.name}?`}
            subDescription='This action cannot be undone.'
            cancel='Cancel'
            confirm='Delete'
            confirmHandler={() => {
                delete props.selectedCategory.experiences[props.experience.id]
                store.dispatch({ type: 'set-category', payload: props.selectedCategory })
                store.dispatch({ type: 'delete-experience', payload: props.experience })
            }}
        />)
        popupPanel.setActive(true)
    }

    return (
        <SExperienceTag awake={awake}>
            <SImage onClick={() => clickHandler()}><Image url={pathFunctions.getExperienceThumbnail(props.experience)} contain version={props.experience.imageVersion}/></SImage>
            <Text onClick={() => clickHandler()} middle oneline bold dark>{props.experience.name}</Text>
            <EditButtons
                editHandler={() => editHandler()}
                deleteHandler={() => deleteHandler()}
            />
        </SExperienceTag>
    )
}

const SExperiences = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-rows: ${p => p.theme.minSize} auto;
`

function Experiences(props: { experiences: Dictionary<IExperience>, newHandler(): void, selectedCategory: ICategory }) {
    const store = useStore()

    const [experiencesReorder, setExperiencesReorder] = useState([])
    const [experienceWasMoved, setExperienceWasMoved] = useState(false)

    function getExperiences() {
        if (!props.experiences) return []

        return Object.values(props.experiences)
                .map(e => <ExperienceTag 
                    key={e.id} 
                    experience={e} 
                    selectedCategory={props.selectedCategory} 
                />)
    }

    useEffect(() => {
        setExperiencesReorder(getExperiences())
	}, [props.experiences])

    useEffect(() => {
        if (experienceWasMoved) {
            let experiences = {}
            for (let orderIndex in experiencesReorder) {
                experiences[experiencesReorder[orderIndex].key] = parseInt(orderIndex)
            }

            props.selectedCategory.experiences = experiences
            store.dispatch({ type: 'set-category', payload: props.selectedCategory as ICategory })

            toast("Changes saved successfully!")
            setExperienceWasMoved(false)
        }
	}, [experienceWasMoved])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setExperiencesReorder(arrayMove(experiencesReorder, oldIndex, newIndex))
        if (oldIndex !== newIndex) setExperienceWasMoved(true)
     }

    return (
        <SExperiences>
            <STitle>
                <SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={icons.experiences} contain dark />
                <Text middle bold big dark>Experiences</Text>
                <Button onClick={() => props.newHandler()} icon={icons.add}>Add</Button>
            </STitle>
            <SGridHolder>
                <SGrid>
                    <SortableList items={experiencesReorder} onSortEnd={onSortEnd} />
                </SGrid>
            </SGridHolder>
        </SExperiences>
    )
}
