import React from 'react'
import styled, { keyframes } from 'styled-components'

import { SVG } from '../components/UI'

import logoIcon from '../assets/loading/icon.svg'
import lineIcon from '../assets/loading/line.svg'

const SLoading = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
`

const IconAnimation = keyframes`
    0% {
        transform: translate(-50%, -50%) scale(1);
    }
    50% {
        transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
`

const LineAnimation = keyframes`
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
    50% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.6);
        opacity: 0;
    }
`

const SBall = styled.div`
    position: relative;
    border-radius: 50%;
    background-color: ${p => p.theme.colors.primary};
    height: 5rem;
    width: 5rem;

    * {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40%;
        height: 40%;
    }

    *:first-child {
        animation: ${IconAnimation} 2s linear infinite;
    }

    *:last-child {
        animation: ${LineAnimation} 2s linear infinite;
    }
`

export default function Loading() {
    return (
        <SLoading>
            <SBall>
                <SVG contain image={logoIcon} />
                <SVG contain image={logoIcon} />
            </SBall>
        </SLoading>
    )
}
