import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import icons from '../../assets/icons'
import { useStore } from '../../hooks'
import { Text, SVG, InputField, RoundIconButton } from '../../components/UI'
import { STitle, SSearch, SGridHolder, SGrid } from '../../pages/'
import { IScene } from '../../store'
import {Collapse} from 'react-collapse'

const SFiles = styled.div<{ active: boolean }>`
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    transition: .5s;
    opacity: ${p => p.active ? 1 : 0};
    ${p => !p.active && css`
        transform: translateX(100%);
    `}

    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-rows: min-content min-content auto;
`

const STop = styled.div`
    position: relative;
    display: grid;
    place-items: center;
`

const SBack = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
`

interface IState {
    search: string,
}

export default function Files(props: { active: boolean, backHandler(): void, selectHandler(sceneId: string): void }) {
    const store = useStore()
    const [state, set] = useState<IState>({
        search: ''
    })
    const [activeIndex, setActiveIndex] = useState(-1)

    function toggleClass(index) {
        setActiveIndex(activeIndex === index ? null : index)
     }

    function getFiles() {
        if (!store?.state?.scenes) return

        let scenesArray = Object.values(store.state.scenes)
        let scenesGroupByName = []
        scenesArray = scenesArray.reverse()
        for (const index in scenesArray) {
            if (scenesGroupByName[scenesArray[index].displayName] == undefined) {
                scenesGroupByName[scenesArray[index].displayName] = []
                scenesGroupByName[scenesArray[index].displayName].push(scenesArray[index])
            } else {
                scenesGroupByName[scenesArray[index].displayName].push(scenesArray[index])
            }
        }

        return Object.values(scenesGroupByName as IScene)
            .filter(scene => {
                if (state.search === '') return true
                return scene[0].displayName.toLowerCase().includes(state.search.toLowerCase())
            })
            .map(function (scene, index) {
                let isInUseVersion = false
                let isInUseFile = false

                let versions = <Collapse isOpened={activeIndex === index}>
                                { scene.map((version) => {
                                    isInUseVersion = false
                                    
                                    for (const index in store.state.experiences) {
                                        if (version.id === store.state.experiences[index].sceneId){
                                        isInUseVersion = true
                                        isInUseFile = true
                                        break
                                        }
                                    }

                                    return (
                                            <FileVersion
                                                key={version.id}
                                                scene={version}
                                                clickHandler={() => props.selectHandler(version.id)}
                                                isUsed={isInUseVersion}
                                            />
                                        )
                                })}
                            </Collapse>
                
                return <div key={scene[0].id}>
                    <File
                        isOpened={activeIndex === index}
                        key={index}
                        scene={scene[0]}
                        clickHandler={() => toggleClass(index)}
                        scenesGroupByName={scenesGroupByName}
                        isUsed={isInUseFile}
                    />
                    {versions}
                </div>
            } )
    }

    return (
        <SFiles active={props.active}>
            <STop>
                <SBack>
                    <RoundIconButton onClick={() => props.backHandler()} icon={icons.arrow} />
                </SBack>
                <STitle style={{ gridTemplateColumns: '2rem min-content' }}>
                    <SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={icons.files} contain dark />
                    <Text middle bold big dark>Files</Text>
                </STitle>
            </STop>
            <SSearch>
                <SVG style={{ width: '70%', height: '70%', margin: 'auto' }} contain primary image={icons.search} />
                <InputField
                    onChange={v => set(p => ({ ...p, search: v }))}
                    value={state.search}
                    placeholder='Search'
                />
            </SSearch>
            <SGridHolder>
                <SGrid>
                    {props.active && getFiles()}
                </SGrid>
            </SGridHolder>
        </SFiles>
    )
}

const SFile = styled.div`
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.theme.colors.light};
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr  min-content;
    padding: .6rem ${p => p.theme.padding};
`

const SEditHistoryGrid = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1.2rem;
    height: 1.2rem;
    width: min-content;
    margin: auto;

    * {
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.colors.primary};
            filter: brightness(120%);
        }
    }
`

function File(props: { scene: IScene, clickHandler(): void, isOpened: boolean, scenesGroupByName: Array<IScene>, isUsed: boolean }) {
    function getDateString(): string {
        return new Date(props.scene.uploadTimestamp).toDateString()
    }

    return (
        <SFile onClick={() => props.clickHandler()}>
            <Text dark bold oneline style={{minWidth:'130px'}}>
                {props.scene.displayName.length > 15 && !props.isOpened ?  props.scene.displayName.substring(0,15) + "..." : props.scene.displayName}
            </Text>

            <Text medium bold oneline center style={{minWidth:'130px'}}>{!props.isOpened ? props.scene.version ? 'Version ' + props.scene.version : 'Version 0' : ''}</Text>

            <Text medium bold oneline center style={{minWidth:'130px'}}>{!props.isOpened ? getDateString() : ''}</Text>

            { props.isUsed && !props.isOpened ? <SVG contain medium image={icons.greenPoint} style={{'backgroundColor':'#3dff00',minWidth:'50px'}} /> : <span style={{minWidth:'50px'}}></span>}

            <SEditHistoryGrid>
                <SVG contain medium image={icons.history}/>
                <SVG contain medium image={props.isOpened ? icons.arrowUp : icons.arrowDown}  style={{width: '11px', height: '11px', margin: 'auto'}}/>
             </SEditHistoryGrid>
        </SFile>
    )
}


const SFileVersion = styled.div`
        border-radius: ${p => p.theme.borderRadius};
        background-color: ${p => p.theme.colors.light};
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.colors.primary};
            * {
                color: ${p => p.theme.colors.light};
            }
        }

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr min-content;
        padding: .6rem ${p => p.theme.padding};
    `

const SHasScriptColumn = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1.2rem;
    height: 1.2rem;
    width: min-content;
`

function FileVersion(props: { scene: IScene, clickHandler(): void, isUsed: boolean}) {
    const store = useStore()
    function getDateString(): string {
        return new Date(props.scene.uploadTimestamp).toDateString()
    }

    return (
        <SFileVersion onClick={() => props.clickHandler()}>
            <span></span>

            <SHasScriptColumn>
                <span>{props.scene.hasCustomScripts ? <SVG contain medium image={icons.folder} style={{marginRight: '3px'}}/> :  ''}</span> <Text medium bold oneline center> Version {props.scene.version ? props.scene.version : 0}</Text>
            </SHasScriptColumn>

            <Text medium bold oneline center>{getDateString()}</Text>

            { props.isUsed ? <SVG contain medium image={icons.greenPoint} style={{'backgroundColor':'#3dff00',minWidth:'50px'}} /> : <span style={{minWidth:'50px'}}></span>}

        </SFileVersion>
    )
}
