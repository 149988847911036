import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import styled from 'styled-components'
import Loading from '../components/Loading'

const SortableItem = SortableElement(({value}) => <div>{value}</div>)

const SHolder = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

const SortableList = SortableContainer(({items}) => {
    return (
        <SHolder>
        {items !== undefined ? items.map((value, index) => (
           <SortableItem key={`item-${value.key}`} index={index} value={value} />
        )) : <Loading/>}
        </SHolder>
    )
})

export default SortableList
