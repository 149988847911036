import styled, { css } from 'styled-components'
import icons from '../../assets/icons'
import { Text, SVG } from '../../components/UI'

export default function StepZeroWizard() {
    return (
            <>
                <SVG style={{ margin:'auto', height: '200px', width: '200px'}} contain image={icons.versionNew} primary/>

                <Text dark bold center big>Create a New Version</Text>
                <Text medium center style={{maxWidth: "600px", margin: 'auto', marginTop: '20px'}}>Create a new App Version when you add or modify files with custom scripts on your experiences. This wizard will guide you on the steps needed to build, test and publish these new app versions.</Text>
                
                <SSteps>
                    <SStep>
                        <Text dark bold center style={{height: '40px'}}>Test Version</Text>
                        <SVG style={{ margin:'auto', height: '40px', width: '40px'}} contain image={icons.versionNew} primary/>
                    </SStep>

                    <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.arrowRight} medium/>

                    <SStep>
                        <Text dark bold center style={{height: '40px'}}>Cloud Building</Text>
                        <SVG style={{ margin:'auto', height: '40px', width: '40px'}} contain image={icons.cloud} primary/>
                    </SStep>

                    <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.arrowRight} medium/>

                    <SStep>
                        <Text dark bold center style={{height: '40px'}}>App Testing</Text>
                        <SVG style={{ margin:'auto', height: '40px', width: '40px'}} contain image={icons.listCheck} primary/>
                    </SStep>

                    <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.arrowRight} medium/>

                    <SStep>
                        <Text dark bold center style={{height: '40px'}}>Store Publishing</Text>
                        <SVG style={{ margin:'auto', height: '40px', width: '40px'}} contain image={icons.windowUP} primary/>
                    </SStep>

                    <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.arrowRight} medium/>
                    
                    <SStep>
                        <Text dark bold center style={{height: '40px'}}>Version Update</Text>
                        <SVG style={{ margin:'auto', height: '40px', width: '40px'}} contain image={icons.versionsCheck} primary/>
                    </SStep>
                </SSteps>
            </>
    )
}

/*STYLES*/
const SSteps = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`

const SStep = styled.div`
    background: #edededed;
    padding: 30px 30px 57px 29px;
    border-radius: 6px;
    margin: 11px;
    width: 20%;
    max-width: 200px;
    height: 150px;
`

