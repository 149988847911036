import React, { useState } from 'react'

const PopupPanel = React.createContext({})

export interface IPopupPanelState {
	active: boolean,
	content?: any,
	wizardText: boolean,
}

export interface IPopupPanel {
	state: IPopupPanelState,
	setActive(value: boolean): void,
	setContent(content: any, showWizardText?: boolean): void
}

// Generic popup.
export function PopupPanelContext(props: any) {
	const [state, set] = useState<IPopupPanelState>({
		active: false,
		wizardText: false
	})

	const value: IPopupPanel = {
		state: state,
		setActive(value) {
			if (value) set(p => ({ ...p, active: value }))
			else set(p => ({ ...p, active: value, content: undefined, wizardText: false }))
		},
		setContent(content, showWizardText = false) { set(p => ({ ...p, content, wizardText: showWizardText })) }
	}

	return (
		<PopupPanel.Provider value={value}>
			{props.children}
		</PopupPanel.Provider>
	)
}

export default PopupPanel