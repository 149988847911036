import React, { useState } from 'react'
import styled from 'styled-components'
import { key } from 'firebase-key'

import Loading from '../components/Loading'
import icons from '../assets/icons'
import { Text, SVG, Button, InputField, Image } from '../components/UI'
import { useFileExists, useStore } from '../hooks'
import { ICategory } from '../store'
import { pathFunctions } from '../utility'
import { STitle } from '../pages'
import { SList, SThumbnail, DropImage } from './'
import { deleteFile, uploadImage } from '../firebase/storage'

const SEditCategory = styled.div`
    background-color: ${p => p.theme.colors.light};
    border-radius: ${p => p.theme.borderRadius};

    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    transition: .3s;
`

const SHorizontal = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${p => p.theme.gridGap};
`

export default function EditCategory(props: { category?: ICategory, onSave(category: ICategory): void }) {
    const [category, setCategory] = useState<ICategory>(props.category ?? { id: key(), imageVersion: 1, order: 1 })
    const store = useStore()
    const [isLoading, setIsLoading] = useState(false)
    const [image, setImage] = useState<File>()
    const [deleteImage, setDeleteImage] = useState(false)

    const incrementVersion = () => setCategory(p => ({...p, imageVersion: p.imageVersion + 1 }))

    async function saveHandler(): Promise<void> {
        const url = pathFunctions.getCategoryThumbnail(category)


        if (image) {
            setIsLoading(true)
            await uploadImage(image, url)
            setIsLoading(false)
        }

        else if (deleteImage) {
            setIsLoading(true)
            await deleteFile(url)
            setIsLoading(false)
        }

        // Save to store.
        store.dispatch({ type: 'set-category', payload: category })
        props.onSave(category)
    }

    function imageSelectHandler(image: File): void {
        setImage(image)
        setDeleteImage(false)
        incrementVersion()
    }

    function deleteImageHandler(): void {
        setDeleteImage(true)
        incrementVersion()
    }

    if (isLoading) return (
        <SEditCategory style={{height: '15rem', width: '15rem'}}>
            <Loading />
        </SEditCategory>
    )

    return (
        <SEditCategory>
            <STitle style={{ margin: 'auto', gridTemplateColumns: '2.5rem auto' }} >
                <SVG dark contain image={icons.categories} style={{ height: '100%', width: '100%' }} />
                <Text dark bold>{props.category ? 'Edit' : 'New'} Category</Text>
            </STitle>
            <SHorizontal>
                <SList style={{ gridTemplateRows: 'auto 10rem' }}>
                    <Text subTitle dark bold>Thumbnail</Text>
                    <SThumbnail>
                        <DropImage
                            storagePath={pathFunctions.getCategoryThumbnail(category)}
                            imageVersion={category.imageVersion}
                            onSelect={image => imageSelectHandler(image)}
                            onDelete={() => deleteImageHandler()}
                        />
                    </SThumbnail>
                </SList>
                <SList style={{ height: 'min-content' }}>
                    <Text subTitle dark bold>Name</Text>
                    <InputField
                        value={category.name}
                        placeholder='Category Name'
                        onChange={v => setCategory(c => ({ ...c, name: v }))}
                    />
                </SList>
            </SHorizontal>
            <Button onClick={() => saveHandler()} icon={!props.category && icons.add}>{props.category ? 'Edit' : 'New'} Category</Button>
        </SEditCategory>
    )
}
