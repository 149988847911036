import { ICategory, IExperience } from '../store'

interface IPathFunctions {
    getCategoryThumbnail(category: ICategory): string,
    getExperienceThumbnail(experience: IExperience): string,
}

export const pathFunctions: IPathFunctions = {
    getCategoryThumbnail(category) {
        return `categories/${category.id}.png`
    },
    getExperienceThumbnail(experience) {
        return `experiences/${experience.id}/Image.png`
    }
}
