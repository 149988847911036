import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {useStore } from '../../hooks'
import icons from '../../assets/icons'
import { IConfig, IScene} from '../../store'
import { Text, SVG, InputField, Button } from '../../components/UI'
import Checkbox from "react-custom-checkbox";
import Theme from '../../theme';

export default function StepThreeWizard() {
    const store = useStore()
    const [testExperiences, setTestExperiences] = useState([])
    const [allAndroidChecked, setAllAndroidChecked] = useState(false)
    const [allIosChecked, setAllIosChecked] = useState(false)

    useEffect(() => {
        if (store.state?.config?.testVersion.experiencesToTest) 
            setTestExperiences(store.state?.config?.testVersion.experiencesToTest)
	}, [store.state?.config?.testVersion.experiencesToTest])

    useEffect(() => {
        validateChecked(testExperiences)
	}, [testExperiences])

    function validateChecked (testExperiences) {
        const configAux = { ...store.state?.config }

        if (testExperiences.length > 0) {
            let allSelected = true
            
            for (let index in testExperiences) {
                if (!testExperiences[index].checkedAndroid || !testExperiences[index].checkedIos) {
                    allSelected = false
                    break
                }
            }

            if (allSelected) {
                configAux.testVersion.status = 'testing-success'
                store.dispatch({ type: 'set-config', payload: configAux })
            } else {
                configAux.testVersion.status = 'testing-inprogress'
                store.dispatch({ type: 'set-config', payload: configAux })
            }
        } else {
            configAux.testVersion.status = 'testing-success'
            store.dispatch({ type: 'set-config', payload: configAux })
        }
	}

    function selectAll() {
        const testExperiencesAux = testExperiences

        for (let index in testExperiencesAux) {
            testExperiencesAux[index].checkedAndroid = true
            testExperiencesAux[index].checkedIos = true
        }

        setAllIosChecked(true)
        setAllAndroidChecked(true)

        setTestExperiences(testExperiencesAux)
        validateChecked(testExperiencesAux)

        let testVersionAux = { ...store.state?.config?.testVersion }
        testVersionAux.experiencesToTest = testExperiencesAux

        store.dispatch({ type: 'set-config', payload: {
            ...store.state?.config, 
            testVersion: testVersionAux,
        } })
    }

    function setCheckedExperience(index, type) {
        const testExperiencesAux = testExperiences

        if (type === 'all') { 
            if (testExperiencesAux[index].checkedAndroid  && testExperiencesAux[index].checkedIos) {
                testExperiencesAux[index].checkedAndroid = false
                testExperiencesAux[index].checkedIos = false
            } else {
                testExperiencesAux[index].checkedAndroid = true
                testExperiencesAux[index].checkedIos = true
            }
           
        } else if (type === 'android') {
            testExperiencesAux[index].checkedAndroid = !testExperiencesAux[index].checkedAndroid
        } else {
            testExperiencesAux[index].checkedIos = !testExperiencesAux[index].checkedIos
        }
       
        setTestExperiences(testExperiencesAux)
        validateChecked(testExperiencesAux)

        const testVersionAux = {
            ...store.state?.config?.testVersion,
            experiencesToTest: testExperiencesAux, 
        }

        store.dispatch({ type: 'set-config', payload: {
            ...store.state?.config, 
            testVersion: testVersionAux,
        } })
    }

    function setCheckedAllColumnExperience(type) {
        const testExperiencesAux = testExperiences

        if (type === 'all-android') {
            for (let index in testExperiencesAux) {
                testExperiencesAux[index].checkedAndroid = !allAndroidChecked
            }
            setAllAndroidChecked(!allAndroidChecked)
        } else {
            for (let index in testExperiencesAux) {
                testExperiencesAux[index].checkedIos = !allIosChecked
            }
            setAllIosChecked(!allIosChecked)
        }

        setTestExperiences(testExperiencesAux)
        validateChecked(testExperiencesAux)

        const testVersionAux = {
            ...store.state?.config?.testVersion,
            experiencesToTest: testExperiencesAux, 
        }

        store.dispatch({ type: 'set-config', payload: {
            ...store.state?.config, 
            testVersion: testVersionAux,
        } })
    }

    return (
        <>
        <SStepGrid>
            <div>
                <Text dark bold style={{height:'auto'}} center>Version String</Text>
                <Text dark bold center style={{fontSize: '25px', paddingTop: '5px'}}>{store.state?.config?.testVersion.version}</Text> 
            </div>
            <div>
                <SVersionList>
                    <VersionContainer style={{background: 'white'}}>
                        <Text dark bold style={{width:'150px'}}>Experiences to test</Text>

                        <VersionHeader style={{width:'80px'}}>
                            <SVG contain image={icons.android} style={{width:'20px', height:'20px', margin: 'auto', marginRight: '3px', marginTop: '1px'}} dark/>
                            <Checkbox
                                checked={allAndroidChecked}
                                icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', margin: 'auto', background: Theme.colors.light}} dark/>}
                                borderColor={Theme.colors.primary}
                                borderRadius={3}
                                size={18}
                                onChange={() => setCheckedAllColumnExperience('all-android')}
                                style={{display: 'block', width: '20px', height: '20px', background: allAndroidChecked ? Theme.colors.primary : Theme.colors.light}}
                            />
                        </VersionHeader>
                        <VersionHeader style={{width:'80px'}}>
                            <SVG contain image={icons.ios} style={{width:'20px', height:'20px', margin: 'auto', marginRight: '3px', marginTop: '1px'}} dark/>
                            <Checkbox
                                checked={allIosChecked}
                                icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', background: Theme.colors.light}} dark/>}
                                borderColor={Theme.colors.primary}
                                borderRadius={3}
                                size={18}
                                onChange={() => setCheckedAllColumnExperience('all-ios')}
                                style={{display: 'block', width: '20px', height: '20px', background: allIosChecked ? Theme.colors.primary : Theme.colors.light}}
                            />
                        </VersionHeader>

                        <Button onClick={() => {selectAll()}} style={{width: '120px', height: '1.5rem'}}>Select all</Button>
                    </VersionContainer>
                </SVersionList>
                
                <SVersionList>
                    {
                         testExperiences && Object.values(testExperiences)
                         .map((experience, index) =>
                            <VersionContainer key={experience.id}>
                                <Text dark bold style={{minWidth:'150px'}}>{experience.name.length > 15 ? experience.name.substring(0,15) + "..." : experience.name}</Text>
                                <div style={{width: '80px', paddingLeft: '23px'}}>
                                    <Checkbox
                                        checked={experience.checkedAndroid}
                                        icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', margin: 'auto', background: Theme.colors.light}} dark/>}
                                        borderColor={Theme.colors.primary}
                                        borderRadius={3}
                                        size={18}
                                        onChange={() => setCheckedExperience(index, 'android')}
                                        style={{display: 'block', width: '20px', height: '20px', background: experience.checkedAndroid ? Theme.colors.primary : Theme.colors.light}}
                                    />
                                </div>
                                <div style={{width: '80px', paddingLeft: '23px'}}>
                                    <Checkbox
                                        checked={experience.checkedIos}
                                        icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', margin: 'auto', background: Theme.colors.light}} dark/>}
                                        borderColor={Theme.colors.primary}
                                        borderRadius={3}
                                        size={18}
                                        onChange={() => setCheckedExperience(index, 'ios')}
                                        style={{display: 'block', width: '20px', height: '20px', background: experience.checkedIos ? Theme.colors.primary : Theme.colors.light}}
                                    />
                                </div>

                                <div style={{width: '80px', paddingLeft: '23px'}}>
                                    <Checkbox
                                        checked={experience.checkedAndroid && experience.checkedIos}
                                        icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', margin: 'auto', background: Theme.colors.light}} dark/>}
                                        borderColor={Theme.colors.primary}
                                        borderRadius={3}
                                        size={18}
                                        onChange={() => setCheckedExperience(index, 'all')}
                                        style={{display: 'block', width: '20px', height: '20px', background: experience.checkedAndroid && experience.checkedIos ? Theme.colors.primary : Theme.colors.light}}
                                    />
                                </div>
                            </VersionContainer>
                        )
                    }
                </SVersionList>
            </div>

        </SStepGrid>

        <SFooter>
            <Text medium bold center>All experiences must be checked to proceed to the next step.</Text>
        </SFooter>
        </>
    )
}

/*STYLES*/
const SVersionList = styled.div`
    overflow: auto;
    max-height: 322px;
`

const SStepGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 2rem;
`

const TextImage = styled.div`
    display: flex;
    justify-content: center;
    grid-gap: 0.5rem;
`

const IconCheck = styled.div`
   grid-template-columns: 1fr  1fr;
    display: grid;
    grid-gap: 0rem;
`

const VersionContainer = styled.div`
    background: #edededed;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    display: grid;
    grid-gap: 2rem;
    padding: 10px;
    border-radius: 6px;
    width: 674px;
    height: 40px;
    margin-top: 10px;
`

const VersionHeader = styled.div`
    display: flex;
`
const SFooter = styled.div`
    position: absolute;
    bottom: 100px;
    width: 100%;
    right: 0px;
`
