import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { useUrl } from '../hooks'

const FileSelectorContainer = styled.input`
    background-color: ${p => p.theme.colors.primary};
    padding: .4rem;
    color: ${p => p.theme.colors.light};
    font-weight: bold;
    border-radius: ${p => p.theme.borderRadius};
    cursor: pointer;
    :hover { filter: brightness(120%); }
`

interface IFileSelectorProps {
	fileHandler(f: any): void
}

export function FileSelector(props: IFileSelectorProps) {
	function handleChange(e) {
		if (e.target.files[0]) props.fileHandler(e.target.files[0])
	}

	return (
		<FileSelectorContainer onChange={e => handleChange(e)} type='file' />
	)
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 20px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 20px;
`

const StyledCheckbox = styled.div<{checked?: boolean}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? 'salmon' : 'papayawhip')};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`
export function Checkbox ({ checked, ...props }) {
    return (
        <CheckboxContainer>
            <HiddenCheckbox checked={checked} {...props} />
            <StyledCheckbox checked={checked}>
            <Icon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
            </Icon>
            </StyledCheckbox>
        </CheckboxContainer>
      )
} 

const ImageContainer = styled.div<{ image?: string, placeholder?: string, contain?: boolean, fit?: boolean }>`
    box-sizing: border-box;
    background-image: url(${p => p.image}), url(${p => p.placeholder});
    background-size: ${p => p.contain ? 'contain' : p.fit ? '100% 100%' : 'cover'};
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
`

interface IImageProps {
	url?: string,
    version?: number,
	image?: string,
	placeholder?: string,
	contain?: boolean,
	fit?: boolean,
	children?: any,
	style?: any
}

export function Image(props: IImageProps) {
	const url = useUrl(props.url)

    function getImage() {
        return url ? (props.version ? `${url}&version=${props.version}` : url) : props.image
    }

	return (
		<ImageContainer
			style={props.style}
			image={getImage()}
			contain={props.contain}
			fit={props.fit}
		> {props.children} </ ImageContainer>
	)
}

export const Text = styled.div<{ big?: boolean, subTitle?: boolean, bold?: boolean, dark?: boolean, alternative?: boolean, medium?: boolean, primary?: boolean, oneline?: boolean, center?: boolean, middle?: boolean, bottom?: boolean }>`
    box-sizing: border-box;
    font-size: ${p => p.big ? p.theme.fontSize.title : p.subTitle ? p.theme.fontSize.subTitle : p.theme.fontSize.text};
    font-weight: ${p => p.bold ? 'bold' : 'normal'};
    color: ${p => p.dark ? p.theme.colors.dark : p.primary ? p.theme.colors.primary : p.medium ? p.theme.colors.medium : p.alternative ? p.theme.colors.alternative : p.theme.colors.light};
    height: 100%;
    white-space: ${p => p.oneline ? 'nowrap' : 'pre-line'};
    
    ${p => p.center && css`
        text-align: center;
    `}

    ${p => p.onClick && css`
        cursor: pointer;
        :hover {
            filter: brightness(120%);
            transform: scale(1.02);
        }
    `}

    ${p => p.middle && css`
        display: flex;
        align-items: center;

        ${p.center && css`
            justify-content: center;
        `}
    `}

    ${p => p.bottom && css`
        display: flex;
        align-items: flex-end;

        ${p.center && css`
            justify-content: center;
        `}
    `}
`

export const SVG = styled.div<{ image: string, contain?: boolean, dark?: boolean, primary?: boolean, alternative?: boolean, medium?: boolean }> `
    mask-image: url(${p => p.image});
    mask-size: ${p => p.contain ? 'contain' : 'cover'};
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${p => p.dark ? p.theme.colors.dark : p.primary ? p.theme.colors.primary : p.medium ? p.theme.colors.medium : p.alternative ? p.theme.colors.alternative : p.theme.colors.light};
    height: 100%;
`

const InputFieldContainer = styled.input<{ center?: boolean }>`
    border: none;
    font-size: 1rem;

    height: 2.5rem;
    background-color: ${p => p.theme.colors.darkLight};
    padding: 0 1.5rem;
    ${p => p.center && css`
        text-align: center;
    `}

    ::placeholder {
        opacity: .2;
    }

    font-weight: bold;
    color: ${p => p.theme.colors.dark};

    :hover {
        filter: brightness(98%);
    }

    border-radius: ${p => p.theme.borderRadius};
`

type TInputFieldProps = {
	autoSelect?: boolean,
	onKeyDown?(e): void,
	center?: boolean,
	placeholder?: string,
	value: string,
	onChange(v: string): void,
	type?: string,
	style?: any
}

export function InputField(props: TInputFieldProps) {
	const inputRef = useRef()

	// Select inputfield if props.autoSelect is true.
	useEffect(() => {
		if (props.autoSelect && inputRef.current) (inputRef.current as any).select()
	}, [inputRef.current])

	return (
		<InputFieldContainer
			ref={inputRef}
			onKeyDown={props.onKeyDown}
			center={props.center}
			placeholder={props.placeholder}
			value={props.value ? props.value : ''}
			onChange={e => props.onChange(e.target.value)}
			type={props.type ? props.type : 'text'}
			style={props.style} />
	)
}

const STextArea = styled.textarea<{ center?: boolean }>`
    height: 100%;
    border: none;
    font-size: 1rem;

    background-color: ${p => p.theme.colors.darkLight};
    padding: 1rem;
    ${p => p.center && css`
        text-align: center;
    `}

    ::placeholder {
        opacity: .2;
    }

    font-weight: bold;
    color: ${p => p.theme.colors.dark};

    :hover {
        filter: brightness(98%);
    }

    border-radius: ${p => p.theme.borderRadius};
    resize: none;
`

type TTextAreaProps = {
	autoSelect?: boolean,
	onKeyDown?(e): void,
	center?: boolean,
	placeholder?: string,
	value: string,
	onChange(v: string): void,
	type?: string,
	style?: any,
	rows?: number,
	cols?: number,
}

export function TextArea(props: TTextAreaProps) {
	const inputRef = useRef()

	// Select inputfield if props.autoSelect is true.
	useEffect(() => {
		if (props.autoSelect && inputRef.current) (inputRef.current as any).select()
	}, [inputRef.current])

	return (
		<STextArea
			rows={props.rows}
			cols={props.cols}
			ref={inputRef}
			onKeyDown={props.onKeyDown}
			center={props.center}
			placeholder={props.placeholder}
			value={props.value ? props.value : ''}
			onChange={e => props.onChange(e.target.value)}
			style={props.style} />
	)
}

const ButtonContainer = styled.div< { dark?: boolean, light?: boolean, center?: boolean, alternative?: boolean, icon?: boolean, reverse?: boolean, transparent?: boolean } >`
    box-sizing: border-box;
    height: ${p => p.theme.minSize};
    background-color: ${p => p.dark ? p.theme.colors.dark : p.light ? p.theme.colors.light : p.alternative ? p.theme.colors.alternative : p.transparent ? 'none' : p.theme.colors.primary};
    ${p => p.light && css`
        border: .13rem solid ${p => p.theme.colors.primary};
    `}
    cursor: pointer;
    :hover {
        filter: brightness(120%);

        ${Text} { 
            color: ${p => p.light ? p.theme.colors.primary : p.theme.colors.light}; 
        }
    }

    ${Text} {
        display: flex;
        align-items: center;
        ${p => p.center && css`justify-content: center;`}
    }

    border-radius: ${p => p.theme.borderRadius};

    padding: 6px;

    ${p => p.icon && css`
        display: flex;
        align-items: center;
        justify-content: center;

        grid-template-columns: ${p.reverse ? `auto ${p.theme.minSize}` : `${p.theme.minSize} auto`};

        ${p.light && css`
            :hover {
                ${SVG} {
                    background-color: ${p => p.theme.colors.primary}
                }
            }
        `}
    `}
`

type TButtonProps = {
	style?: any,
	onClick(): void,
	dark?: boolean,
	light?: boolean,
	transparent?: boolean,
	alternative?: boolean,
	icon?: any,
	iconRight?: any,
	disabled?: boolean,


	children?: any,
	left?: boolean,
	reverse?: boolean,
}

export function Button(props: TButtonProps) {
	function getContent() {
		let list = []

		if (props.icon) list.push(<SVG style={props.children ? { marginRight: '6px', height: '15px', width: '15px' } : { margin: 'auto', height: '20px', width: '20px' }} contain key='icon' primary={props.light} image={props.icon} />)
        if (props.children) list.push(<Text key='text' style={{ whiteSpace: 'nowrap' }} bold primary={props.light}> {props.children} </Text>)
		if (props.iconRight) list.push(<SVG style={{ margin: '6px 0 0 2px', height: '7px', width: '7px'}} contain key='iconRight' primary={props.light} image={props.iconRight} />)
		if (props.reverse) list = list.reverse()

		return list
	}

	return (
		<ButtonContainer 
                    icon={props.icon} 
                    transparent={props.transparent} 
                    reverse={props.reverse} 
                    center={!props.left} 
                    onClick={props.disabled ? () => false : () => props.onClick()} 
                    alternative={props.alternative} 
                    dark={props.disabled ? true : props.dark} 
                    light={props.light} 
                    style={props.disabled ? {cursor:'no-drop'} : props.style}>
			{getContent()}
		</ButtonContainer>
	)
}

const SRoundIconButton = styled.div`
    height: 80%;
    width: 80%;
    border-radius: 50%;
    background-color: ${p => p.theme.colors.primary};
    :hover {
        filter: brightness(120%);
    }
    cursor: pointer;

    display: grid;
    place-items: center;
    * {
        width: 100%;
        height: 100%;
    }
`

export function RoundIconButton(props: { icon: string, onClick(): void }) {
	return (
		<SRoundIconButton onClick={() => props.onClick()}>
			<SVG contain image={props.icon} />
		</SRoundIconButton>
	)
}