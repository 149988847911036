import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {useStore } from '../../hooks'
import icons from '../../assets/icons'
import { Text, SVG, InputField, Button } from '../../components/UI'
import { IConfig, IExperience, IScene} from '../../store'
import Loading from '../../components/Loading'

export default function StepOneWizard() {
    const store = useStore()
    const [enableVersion, setEnableVersion] = useState(false)
    const [version, setVersion] = useState('')
    const [testExperiences, setTestExperiences] = useState([])

    useEffect(() => {
        if(!store.state?.config?.testVersion.version) {
            const newTestVersion = store.state?.config.liveVersion.split('.')[0] + '.' + store.state?.config.liveVersion.split('.')[1] + '.' + (parseInt(store.state?.config.liveVersion.split('.')[2]) + 1)
            setVersion(newTestVersion)
        } else {
            setVersion(store.state?.config?.testVersion.version)
        }
	}, [store.state?.config?.testVersion.version])

    useEffect(() => {
        if(version) {
            const testVersionAux = {
                ...store.state?.config?.testVersion,
                version, 
            }

            if (store.state?.experiences) {
                let experiencesToTestAux = []
    
                for (let index in store.state?.experiences) {
                    if (store.state?.experiences[index].sceneIdTest != undefined && store.state?.experiences[index].sceneIdTest) {
                        let sceneName = ''
                        let version = 0
        
                        for (let j in store.state?.scenes) {
                            if (store.state?.scenes[j].id  == store.state?.experiences[index].sceneIdTest) {
                                sceneName = store.state?.scenes[j].displayName
                                version = store.state?.scenes[j].version
                                break
                            }
                        }
        
                        const experience = {
                            ...store.state?.experiences[index], 
                            checkedAndroid: false,
                            checkedIos: false,
                            sceneName,
                            version
                        }
        
                        experiencesToTestAux.push(experience)
                    }
                }
        
                setTestExperiences(experiencesToTestAux)
    
                if(experiencesToTestAux.length > 0) {
                    testVersionAux.experiencesToTest = experiencesToTestAux
                }
            }

            store.dispatch({ type: 'set-config', payload: {
                    ...store.state?.config, 
                    testVersion: testVersionAux,
                }
            })
        }
	}, [version])

    return (
        <>
        <SStepGrid>
            <div>
                <Text dark bold style={{height:'auto'}} center>Version String</Text>
                <TextImage style={{marginTop:'10px'}}>
                    { enableVersion ? 
                        <InputField
                            onChange={v => setVersion(v)}
                            value={version}
                            placeholder='Version'
                            style={{textAlign: 'center', padding:'0px', width:'80px',fontSize: '25px'}}
                        /> : 
                        <Text dark bold center style={{fontSize: '25px', paddingTop: '5px',  width:'80px'}}>{version}</Text> 
                    }
                    <Button onClick={() => setEnableVersion(!enableVersion)} icon={icons.edit} style={{width:'35px',height:'35px', marginTop: '2px'}} light></Button>
                </TextImage>
            </div>

            <div>
                <Text dark bold style={{height:'auto'}}>Experiences to update custom scripts</Text>
                <SVersionList>
                    { testExperiences &&
                         Object.values(testExperiences).map((experience, index) =>
                            <VersionContainer key={experience.id}>
                                <Text dark bold>{experience.name.length > 15 ? experience.name.substring(0,15) + "..." : experience.name}</Text>
                                <TextImage style={{justifyContent: 'left'}}>
                                    <SVG contain dark image={icons.files} style={{width:'15px', height:'15px', marginLeft: '10px', marginTop: '2px'}} />
                                    <Text dark bold style={{minWidth:'50px'}}>
                                        {experience.sceneName.length > 15 ? experience.sceneName.substring(0,15) + "..." : experience.sceneName}
                                    </Text>
                                </TextImage>
                                <Text dark bold center>Version {experience.version}</Text>
                            </VersionContainer>)
                    }
                </SVersionList>
            </div>

        </SStepGrid>

        <SFooter>
            <TextImage>
                <SVG contain primary image={icons.warning} style={{width:'40px', height:'40px', marginLeft: '10px', marginTop: '-10px'}} />
                <Text medium bold center >Make sure all custom scripts involved are properly pushed to the repository, then press Next to start building.</Text>
            </TextImage>
        </SFooter>
        </>
    )
}

/*STYLES*/
const SVersionList = styled.div`
    overflow: auto;
    max-height: 322px;
`

const SStepGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 2rem;
`

const TextImage = styled.div`
    display: flex;
    justify-content: center;
    grid-gap: 0.5rem;
`

const VersionContainer = styled.div`
    background: #edededed;
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    grid-gap: 2rem;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    height: 40px;
    margin-top: 10px;
`

const SFooter = styled.div`
    position: absolute;
    bottom: 100px;
    width: 100%;
    right: 0px;
`

