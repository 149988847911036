import React, { useState, useEffect, useRef } from 'react'
import { logIn, trackAuthState, register, logOut as firebaseLogOut } from '../firebase/auth'
import { getContent, setContent, onDisconnect, subscribeToContent, Paths, getFiltered } from '../firebase/database'
import { Paths as routerPaths } from '../context/RouterContext'

import { useRouter, useStore } from '../hooks'
import { IStore, Dictionary } from '../store'

const Auth = React.createContext({})

export interface IUser {
	id?: string,
	email: string,
	firstName?: string,
	lastName?: string,
}

export interface IAuth {
	state: IAuthState,
	logIn(email: string, password: string): Promise<IUser>,
	register(user: IUser, password: string): Promise<IUser>,
	logOut(): void,
}

export interface IAuthState {
	user?: IUser,
	isTrackingAuthState?: Boolean,
	loginCallback?(user: IUser): void,
}

export function AuthContext(props) {
	const router = useRouter()
	const store = useStore()
	const [state, set] = useState<IAuthState>({})

	const routerRef = useRef(router)
	const stateRef = useRef(state)
	routerRef.current = router
	stateRef.current = state

	// Attemp to login, set loginCallback for later usage.
	function logInHandler(email: string, password: string): Promise<IUser> {
		return new Promise(async (resolve, reject) => {
			try {
				set(prev => ({ ...prev, loginCallback: function (user: IUser) { resolve(user) } }))
				await logIn(email, password)
			} catch (error) { reject(error) }
		})
	}

	// Clear user from state, and logout from firebase.
	function logOutHandler(): void {
		set(prev => ({ ...prev, user: undefined, isTrackingAuthState: false }))
		firebaseLogOut()
	}

	// Register to firebase auth, and write new user to database.
	function registerHandler(user: IUser, password: string): Promise<IUser> {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await register(user.email, password) as any

				user.id = response.user.uid

				await setContent(`${Paths.users}/${user.id}`, user)

				await logInHandler(user.email, password)

				resolve(user)
			} catch (error) { reject(error) }
		})
	}

	const auth: IAuth = {
		state: state,
		logIn: logInHandler,
		register: registerHandler,
		logOut: logOutHandler
	}

	// Send user to login page if its not logged.
	useEffect(() => {
		const timeout = setTimeout(function () {
			const router = routerRef.current
			const state = stateRef.current

			if (router.path != routerPaths.login && (!state.user || !state.user.id))
				router.setPath(routerPaths.login)


		}.bind(this), 2500)

		return () => clearTimeout(timeout)

	}, [state.user, router.path])

	useEffect(() => {
		async function handleStateChange(localUser) {
			if (localUser) {
				const user: IUser = {
					email: localUser.email,
					id: localUser.uid
				}

				// Set user.
				set({ ...state, user: user, isTrackingAuthState: true })

				// Call callback.
				if (state.loginCallback) state.loginCallback(user)
				if (router.path === routerPaths.login) router.setPath(routerPaths.home)
			}

			else set({ ...state, user: null, isTrackingAuthState: true })
		}

		if (!state.isTrackingAuthState) trackAuthState(handleStateChange)

	}, [state.isTrackingAuthState])

	return <Auth.Provider value={auth}>{props.children}</Auth.Provider>
}

export default Auth