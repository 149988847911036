import React from 'react'
import styled from 'styled-components'
import logo from '../brand/logo.svg'

const SLogo = styled.div<{ image: string }>`
    background-image: url(${p => p.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
`

export default function Logo() {
    return (
        <SLogo image={logo} />
    )
}