import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { useStore, useAwakeBool, usePopupPanel } from '../hooks'
import { SLayout, SSearch, SGrid, STitle, SGridHolder, EditButtons } from './'
import { Text, InputField, SVG } from '../components/UI'
import icons from '../assets/icons'
import { Dictionary, IScene } from '../store'
import Unity from '../components/Unity'

import Confirmation from '../panels/Confirmation'
import EditScene from '../panels/EditScene'
import {Collapse} from 'react-collapse'

const SFiles = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-rows: min-content min-content min-content auto;
`

const STop = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr min-content;
`

const SButton = styled.button`
    border: none;
    background: #f2fff9;
    padding: 5px;
    text-align: left;
    cursor: pointer;
    :hover { * { color: ${p => p.theme.colors.primary}; } };
`

interface IState {
    search: string,
    selected?: IScene
}

export default function Files() {
    const awake = useAwakeBool()
    const store = useStore()
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction:'asc' })
    const [files, setFiles] = useState([])
    const [activeIndex, setActiveIndex] = useState(-1)

    const [state, set] = useState<IState>({
        search: ''
    })

    const requestSort = key => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
        }
        setSortConfig({ key, direction })
    }

     function toggleClass(index) {
        setActiveIndex(activeIndex === index ? null : index)
     }

     const removeVersionList = id =>{
        setFiles(getFiles(id))
    }

    function getFiles(idToRemove = '') {
        if (!store.state?.scenes) return

        let direction = { one: -1, two: 1 }

        if (sortConfig?.direction === 'desc') {
            direction = { one: 1, two: -1 }
        }

        let scenesArray = Object.values(store.state.scenes)
        let scenesGroupByName = []
        scenesArray = scenesArray.reverse()
        for (const index in scenesArray) {
            if (scenesGroupByName[scenesArray[index].displayName] == undefined) {
                scenesGroupByName[scenesArray[index].displayName] = []
                scenesGroupByName[scenesArray[index].displayName].push(scenesArray[index])
            } else {
                scenesGroupByName[scenesArray[index].displayName].push(scenesArray[index])
            }
        }
        
        return Object.values(scenesGroupByName as IScene)
                .filter(scene => {
                    if (state.search === '') return true
                    return scene[0].displayName.toLowerCase().includes(state.search.toLowerCase())
                })
                .sort(function (a, b) {
                    if (sortConfig?.key === 'date')
                        return a[0].uploadTimestamp ?? 0 > b[0].uploadTimestamp ?? 0 ? direction.one : direction.two
                    
                    if (sortConfig?.key === 'name') {
                        if (sortConfig?.direction === 'asc') return a[0].displayName.trim().toLowerCase().localeCompare(b[0].displayName.trim().toLowerCase())
                        if (sortConfig?.direction === 'desc') return b[0].displayName.trim().toLowerCase().localeCompare(a[0].displayName.trim().toLowerCase())
                    }
                })
                .map(function (scene, index) {
                    let isInUseVersion = false
                    let isInUseFile = false
                    let conutVersions = 0

                    let versions = <Collapse isOpened={activeIndex === index}>
                                    {scene.map((version) => {
                                        if (idToRemove == version.id) {
                                            return
                                        }

                                        isInUseVersion = false
                                        
                                        for (const index in store.state.experiences) {
                                            if (version.id === store.state.experiences[index].sceneId){
                                            isInUseVersion = true
                                            isInUseFile = true
                                            break
                                            }
                                        }

                                        conutVersions++

                                        return (
                                                <FileVersion
                                                    key={version.id}
                                                    scene={version}
                                                    selected={state?.selected?.id === version.id}
                                                    clickHandler={() => set(p => ({ ...p, selected: version }))}
                                                    isUsed={isInUseVersion}
                                                    removeVersionList={() => removeVersionList(version.id)}
                                                />
                                            )
                                    })}
                                </Collapse>

                    if (conutVersions === 0 || idToRemove == scene[0].id) {
                        return
                    }
                    
                    return <div key={scene[0].id}>
                        <File
                            isOpened={activeIndex === index}
                            key={index}
                            scene={scene[0]}
                            clickHandler={() => toggleClass(index)}
                            scenesGroupByName={scene}
                            isUsed={isInUseFile}
                            removeVersionList={id => removeVersionList(id)}
                        />
                        {versions}
                    </div>
                } )
    }

    useEffect(() => {
        if (store.state?.scenes && !state.selected) {
            setFiles(getFiles())
        }
    }, [store.state?.scenes])

    useEffect(() => {
        setFiles(getFiles())
    }, [activeIndex])

    useEffect(() => {
        setFiles(getFiles())
    }, [state.search, sortConfig, state.selected])

    return (
        <SLayout awake={awake}>
            <SFiles>
                <STitle>
                    <SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={icons.files} contain dark />
                    <Text middle bold big dark>Files</Text>
                </STitle>
                <SSearch>
                    <SVG style={{ width: '70%', height: '70%', margin: 'auto' }} contain primary image={icons.search} />
                    <InputField
                        onChange={v => set(p => ({ ...p, search: v }))}
                        value={state.search}
                        placeholder='Search'
                    />
                </SSearch>
                <STop>
                    <SButton type="button" onClick={() => requestSort('name')}>
                        <Text medium bold center>Name {sortConfig?.key === 'name'? sortConfig?.direction === 'asc' ? '↑' : '↓' : ''}</Text>
                    </SButton>

                    <SButton>
                        <Text medium bold center>Version</Text>
                    </SButton>

                    <SButton type="button" onClick={() => requestSort('date')}>
                        <Text medium bold center>Date/Hour  {sortConfig?.key === 'date'? sortConfig?.direction === 'asc' ? '↑' : '↓' : ''}</Text>
                    </SButton>

                    <span></span>
                </STop>
                <SGridHolder>
                    <SGrid>
                        {files}
                    </SGrid>
                </SGridHolder>
            </SFiles>
            <Unity sceneId={state?.selected?.id} />
        </SLayout>
    )
}

const SFile = styled.div`
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.theme.colors.light};
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr  min-content;
    padding: .6rem ${p => p.theme.padding};
`

const SEditHistoryGrid = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1.2rem;
    height: 1.2rem;
    width: min-content;
    margin: auto;

    * {
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.colors.primary};
            filter: brightness(120%);
        }
    }
`

function File(props: { scene: IScene, clickHandler(): void, isOpened: boolean, scenesGroupByName: Array<IScene>, isUsed: boolean, removeVersionList(id: string): void }) {
    const popupPanel = usePopupPanel()
    const store = useStore()
    function getDateString(): string {
        return new Date(props.scene.uploadTimestamp).toDateString()
    }

    function deleteHandler(): void {
        popupPanel.setContent(<Confirmation
            title='Delete'
            icon={icons.delete}
            description={`Are you sure you want to delete ${props.scene.displayName} and all version (${props.scenesGroupByName.length} version/s)?`}
            subDescription='This action cannot be undone.'
            cancel='Cancel'
            confirm='Delete'
            confirmHandler={() => {
                for (const index in props.scenesGroupByName) {
                    store.dispatch({ type: 'delete-scene', payload: props.scenesGroupByName[index] })
                    props.removeVersionList(props.scenesGroupByName[index].id)
                }
            }}
        />)
        popupPanel.setActive(true)
    }

    return (
        <SFile onClick={() => props.clickHandler()}>
            <Text dark bold oneline style={{minWidth:'130px'}}>
                {props.scene.displayName.length > 15 && !props.isOpened ?  props.scene.displayName.substring(0,15) + "..." : props.scene.displayName}
            </Text>

            <Text medium bold oneline center style={{minWidth:'130px'}}>{!props.isOpened ? props.scene.version ? 'Version ' + props.scene.version : 'Version 0' : ''}</Text>

            <Text medium bold oneline center style={{minWidth:'130px'}}>{!props.isOpened ? getDateString() : ''}</Text>

            { props.isUsed && !props.isOpened ? <SVG contain medium image={icons.greenPoint} style={{'backgroundColor':'#3dff00',minWidth:'50px'}} /> : <span style={{minWidth:'50px'}}></span>}

            <SEditHistoryGrid>
                <SVG contain medium image={icons.history}/>
                <SVG contain medium image={props.isOpened ? icons.arrowUp : icons.arrowDown}  style={{width: '11px', height: '11px', margin: 'auto'}}/>
             </SEditHistoryGrid>
             
             <EditButtons deleteHandler={deleteHandler} />
        </SFile>
    )
}

const SFileVersion = styled.div<{ selected: boolean }>`
        border-radius: ${p => p.theme.borderRadius};
        background-color: ${p => p.selected ? p.theme.colors.primary : p.theme.colors.light};
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.colors.primary};
            * {
                color: ${p => p.theme.colors.light};
            }
        }

        ${p => p.selected && css`
            * {
                color: ${p => p.theme.colors.light};
            }
        `}
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr min-content;
        padding: .6rem ${p => p.theme.padding};
    `

const SHasScriptColumn = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1.2rem;
    height: 1.2rem;
    width: min-content;
`

function FileVersion(props: { scene: IScene, selected: boolean, clickHandler(): void, isUsed: boolean, removeVersionList(): void}) {
    const popupPanel = usePopupPanel()
    const store = useStore()
    function getDateString(): string {
        return new Date(props.scene.uploadTimestamp).toDateString()
    }

    function deleteHandler(): void {
        popupPanel.setContent(<Confirmation
            title='Delete'
            icon={icons.delete}
            description={`Are you sure you want to delete ${props.scene.displayName} (Version: ${props.scene.version ? props.scene.version : 0})?`}
            subDescription='This action cannot be undone.'
            cancel='Cancel'
            confirm='Delete'
            confirmHandler={() => {
                store.dispatch({ type: 'delete-scene', payload: props.scene })
                props.removeVersionList()
            }}
        />)
        popupPanel.setActive(true)
    }

    return (
        <SFileVersion selected={props.selected} onClick={() => props.clickHandler()}>
            <span></span>

            <SHasScriptColumn>
                <span>{props.scene.hasCustomScripts ? <SVG contain medium image={icons.folder} style={{marginRight: '3px'}}/> :  ''}</span> <Text medium bold oneline center> Version {props.scene.version ? props.scene.version : 0}</Text>
            </SHasScriptColumn>

            <Text medium bold oneline center>{getDateString()}</Text>

            { props.isUsed ? <SVG contain medium image={icons.greenPoint} style={{'backgroundColor':'#3dff00',minWidth:'50px'}} /> : <span style={{minWidth:'50px'}}></span>}


            <EditButtons deleteHandler={deleteHandler} />
        </SFileVersion>
    )
}
