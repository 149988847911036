import { gitlab } from "../brand"

const getUrl = (projectId: string | number, lastPart: string): string => `${gitlab.baseUrlGitLab}/projects/${projectId}/repository/${lastPart}`
const headers = {
    'PRIVATE-TOKEN': gitlab.accessTokenGitLab,
}

export const getSubmoduleLastCommit = (): Promise<Response> => new Promise(async (resolve, reject) => {
    try {
        const response = await fetch(getUrl(gitlab.idCustomScripts, 'branches/master'), { method: 'GET', headers })
        resolve(response)
    } catch (error) { reject(error) }
})

export const updateSubmodule = (commitId: string): Promise<Response> => new Promise(async (resolve, reject) => {
    try {
        const response = await fetch(getUrl(gitlab.idMainRepo, `submodules/${gitlab.subModuleFolder}`), {
            method: 'PUT',
            headers: { ...headers, 'Content-type': 'application/x-www-form-urlencoded' },
            body: `branch=master&commit_sha=${commitId}&commit_message=update submodule reference`
        })
        resolve(response)
    } catch (error) { reject(error) }
})

export const getSettingsFile = (): Promise<Response> => new Promise(async (resolve, reject) => {
    try {
        const response = await fetch(getUrl(gitlab.idMainRepo, `files/${gitlab.filePathVersion}?ref=master`), { method: 'GET', headers })
        resolve(response)
    } catch (error) { reject(error) }
})

export const updateSettingsFile = (newFile: any, appVersion: string): Promise<Response> => new Promise(async (resolve, reject) => {
    try {
        const response = await fetch(getUrl(gitlab.idMainRepo, `files/${gitlab.filePathVersion}?ref=master`), {
            method: 'PUT',
            headers: {...headers, 'Content-type': 'application/json; charset=UTF-8' },
            body: JSON.stringify({
                branch: 'master',
                author_email: 'team@treeview.studio', 
                author_name: 'Treeview Studios',
                content: newFile,
                commit_message: `App version ${appVersion}`
            })
        })
        resolve(response)
    } catch (error) { reject(error) }
})
