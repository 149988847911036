import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { storageUrl, urls } from '../brand'

import Loading from './Loading'

const SUnity = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: ${p => p.theme.borderRadius};
`

const SLoading = styled.div`
    background: linear-gradient(0deg, ${p => p.theme.colors.primary} 0%, ${p => p.theme.colors.alternative} 100%);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    * {
        * {
            width: 7rem;
            height: 7rem;
        }
    }
`

// Ebeded Unity WebGL Canvas with hardcoded loader.
export default function Unity(props: { sceneId: string }) {
	const [loading, setLoading] = useState(true)

	// Close loader component after 4 seconds. (This shouldn't be a hardcoded number)
	useEffect(() => {
		setLoading(true)
		const timeout = setTimeout(() => setLoading(false), 4000)
		return () => clearTimeout(timeout)
	}, [props.sceneId])

	return (
		<SUnity>
			<iframe
				height='100%'
				width='100%'
				src={urls.getUnityViewerUrl(props.sceneId)}
			/>
			{loading && <SLoading><Loading /></SLoading>}
		</SUnity>
	)
}
