import firebase from 'firebase'

import { firebaseConfig } from '../brand'

import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/analytics'

firebase.initializeApp(firebaseConfig)

export default firebase