import firebase from './'

const database = firebase.database()

export enum Paths {
	users = 'users',
	categories = 'categories',
	config = 'config',
	experiences = 'experiences',
	scenes = 'scenes'
}

// Read once content from database at path.
export const getContent = (path: string) => new Promise(async (resolve, reject) => {
	try {
		const snapshot = await database.ref(path).once('value')
		resolve(snapshot.val())
	} catch (error) { reject(error) }
})

// Subscribe to database changes at path.
export const subscribeToContent = (path: string, callback: (value: any) => void) => {
	return database.ref(path).on('value', snapshot => {
		if (snapshot) callback(snapshot.val())
	})
}

// Get a filtered dictionary from database.
export const getFiltered = (path: string, filter: string, value: any) => new Promise(async (resolve, reject) => {
	try {
		const snapshot = await database.ref(path).orderByChild(filter).equalTo(value).once('value')
		resolve(snapshot.val())
	} catch (error) { reject(error) }
})

// Write to database.
export const setContent = (path: string, payload: any) => new Promise(async (resolve, reject) => {
	try {
		if (path) {
			const snapshot = await database.ref(path).set(payload)
			resolve(snapshot)
		}
		else reject('null data')
	} catch (error) { reject(error) }
})

// Set value to database at path when the user disconnects.
export const onDisconnect = (path: string, value: any) => {
	if (path && value != null) database.ref(path).onDisconnect().set(value)
}