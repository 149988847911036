import React, { useRef, useState, useCallback } from 'react'
import styled from 'styled-components'

import { SVG, Text, Image } from '../components/UI'
import icons from '../assets/icons'
import { useFileExists } from '../hooks'
import Loading from '../components/Loading'

import { uploadImage, deleteFile } from '../firebase/storage'
import {useDropzone} from 'react-dropzone'

export const SList = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

export const SThumbnail = styled.div`
    border: .15rem dashed ${p => p.theme.colors.medium};

    transition: .5s;

    cursor: pointer;
    :hover {
        border: .15rem solid ${p => p.theme.colors.primary};
    }
`

const SDropImage = styled.div`
    height: 100%;
    display: grid;
    place-items: center;
    position: relative;
`

const SEdit = styled.div`
    position: absolute;
    width: ${p => p.theme.minSize};
    height: ${p => p.theme.minSize};
    top: 0;
    right: 0;
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.theme.colors.primary};
    cursor: pointer;
    :hover {
        filter: brightness(120%);
    }
    display: grid;
    place-items: center;
    * {
        width: 40%;
        height: 40%;
    }
`

const SHorizontal = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: .3rem;
`

export function DropImage(props: { storagePath: string, imageVersion: number, onSelect(image: File): void, onDelete(): void }) {
    const inputRef = useRef()
    const imageExists = useFileExists(props.storagePath, props.imageVersion)
    const [image, setImage] = useState(true)
    const [uploading, setUploading] = useState(false)
    const [localImage, setLocalImage] = useState()

    const onDrop = useCallback(acceptedFiles => {
        readFile(acceptedFiles[0])
      }, [])
      const {getRootProps, getInputProps} = useDropzone({onDrop})

    function browseHandler(): void {
        console.log('what')
        //@ts-ignore
        if (inputRef.current) inputRef.current.click()
    }

    async function deleteHandler(): Promise<void> {
        props.onDelete()
        setImage(false)
    }

    function readFile(file: File): void {
        setUploading(true)
        const reader = new FileReader()
        reader.onload = (event: any) => {
            setLocalImage(event.target.result)
            setImage(true)
            props.onSelect(file)
            setUploading(false)
        }
        reader.readAsDataURL(file)
    }

    function handleChange(e: any): void {
        if (e.target.files[0]) {
            readFile(e.target.files[0])
        }
    }

    const content = (imageExists.loading || uploading) ? <Loading /> : ((imageExists.value && image) || localImage) ?
        <Image style={{ position: 'relative' }} image={localImage} url={localImage ? undefined : props.storagePath} version={props.imageVersion} contain>
            <SEdit onClick={() => browseHandler()}><SVG contain image={icons.replace} /></SEdit>
            <SEdit style={{top:'45px'}} onClick={() => deleteHandler()}><SVG contain image={icons.delete} /></SEdit>
        </Image> :
        <SDropImage>
            <SList style={{ placeItems: 'center', gridGap: '.2rem' }}>
                <SVG style={{ height: '2rem', width: '2rem' }} primary contain image={icons.upload} />
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        <>
                            <Text bold dark oneline center>Drag and drop</Text>
                            <SHorizontal>
                                <Text medium oneline>or</Text>
                                <Text primary oneline onClick={() => {}}>browse</Text>
                                <Text medium oneline>to choose image</Text>
                            </SHorizontal>
                        </>
                    }
                </div>
            </SList>

        </SDropImage>

    return (
        <div style={{ height: '100%' }}>
            {content}
            <input type='file' ref={inputRef} style={{ display: 'none' }} onChange={e => handleChange(e)} />
        </div>
    )
}
