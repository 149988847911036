import React from 'react'
import styled, { css } from 'styled-components'

import { SVG } from '../components/UI'
import icons from '../assets/icons'

export const SLayout = styled.div<{ awake: boolean }>`
    position: relative;
    padding: 2rem 10rem;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    grid-gap: 3.5rem;

    transition: .5s;
    opacity: ${p => p.awake ? 1 : 0};
    ${p => !p.awake && css`
        transform: translateX(-2rem);
    `}

    overflow: hidden;
`

export const STitle = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-columns: 2rem auto 7rem;
`

export const SSearch = styled.div`
    display: grid;
    grid-template-columns: ${p => p.theme.minSize} auto;
    grid-gap: ${p => p.theme.gridGap};
`

export const SGridHolder = styled.div`
    position: relative;
    overflow-y: auto;
`

export const SGrid = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    margin-right: 20px;
    grid-gap: ${p => p.theme.gridGap};
`

const SEditButtons = styled.div`
    display: grid;
    place-items: center;
    padding: 0 1rem;
`

const SEditButtonsGrid = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1.2rem;
    height: 1.2rem;
    width: min-content;
    grid-gap: ${p => p.theme.gridGap};

    * {
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.colors.primary};
            filter: brightness(120%);
        }
    }
`

export function EditButtons(props: { editHandler?(): void, deleteHandler?(): void, selectHandler?(): void, light?: boolean }) {
    return (
        <SEditButtons>
            <SEditButtonsGrid>
                { props.editHandler &&
                    <SVG onClick={() => props.editHandler()} contain medium image={icons.edit} />
                }

                { props.deleteHandler &&
                    <SVG onClick={() => props.deleteHandler()} contain medium image={icons.delete} />
                }
                
                {props.selectHandler &&
                    <SVG onClick={() => props.selectHandler()} contain medium image={icons.arrange} />
                }
            </SEditButtonsGrid>
        </SEditButtons>
    )
}
