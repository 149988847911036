import React, { useState } from 'react'

const Wizard = React.createContext({})

export interface IWizardState {
    triggerBuilds: boolean
}

export interface IWizard {
	state: IWizardState,
    set: React.Dispatch<React.SetStateAction<IWizardState>>
}

export function WizardContext(props: any) {
	const [state, set] = useState<IWizardState>({
        triggerBuilds: false
	})

	return (
		<Wizard.Provider value={{state, set}}>
			{props.children}
		</Wizard.Provider>
	)
}

export default Wizard