import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { useAuth, usePopupPanel, useRouter, useStore } from '../hooks'
import icons from '../assets/icons'
import { Text, Button, SVG } from './UI'
import Confirmation from '../panels/Confirmation'
import { IConfig } from '../store'

import Logo from './Logo'
import { Paths } from '../context/RouterContext'
import ContainerWizard from '../panels/wizard/ContainerWizard'
import Loading from '../components/Loading'


function MenuAppVersion() {
	const store = useStore()
	const [showMenu, setShowMenu] = useState(false)
	const popupPanel = usePopupPanel()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (store.state?.config?.testVersion) {
			setLoading(false)
		}
	}, [store.state?.config?.testVersion])

	function startWizard() {
		setShowMenu(!showMenu)
		popupPanel.setContent(<ContainerWizard />, true)
		popupPanel.setActive(true)
	}

	return (
		<>
			{showMenu && <SBackground onClick={() => setShowMenu(!showMenu)} />}
			<SWrapperMenuVersion>
				<Button transparent onClick={() => setShowMenu(!showMenu)} icon={icons.versions} iconRight={icons.arrowDown}>Versions</Button>
				{showMenu &&
					<SVersionPopUp>
						{loading ? <Loading /> :
							<>
								<p>Live</p>
								<SColumnsLive>
									<p style={{ fontSize: '25px', fontWeight: 'bold' }}> {store.state?.config.liveVersion}</p>
									<SVG contain medium image={icons.greenPoint} style={{ 'backgroundColor': '#3dff00', width: '20px', height: '20px' }} />
								</SColumnsLive>
								<SColumnsTest>
									<p>Test <br /> {store.state?.config?.testVersion.version ? store.state?.config?.testVersion.version : '----'}</p>
									<Button onClick={() => startWizard()} icon={
										store.state?.config?.testVersion.status ?
											store.state?.config?.testVersion.status == 'build-failure' ?
												icons.versionWarning : icons.versionProgress : icons.versionNew
									}
										style={{ width: '41px', height: '40px' }}></Button>
								</SColumnsTest>
							</>
						}
					</SVersionPopUp>
				}
			</SWrapperMenuVersion>
		</>
	)
}

export default function TopBar() {
	const router = useRouter()
	const auth = useAuth()
	const popupPanel = usePopupPanel()
	const isCategoriesSelected = router.path === Paths.home
	const isFilesSelected = router.path === Paths.file

	// Show popup panel to confirm logout action.
	function logOutHandler(): void {
		// Set popup content.
		popupPanel.setContent(
			<Confirmation
				title='Logout'
				icon={icons.logout}
				description='Are you sure you want to logout?'
				subDescription='You will be return to the login screen.'
				cancel='Cancel'
				confirm='Logout'
				confirmHandler={() => {
					auth.logOut()
					router.setPath(Paths.login)
				}}
			/>
		)
		// Show popup.
		popupPanel.setActive(true)
	}

	return (
		<STopBar>
			<SLeft>
				<SLogo onClick={() => router.setPath(Paths.home)}><Logo /></SLogo>
				<div />
				<Button light={isCategoriesSelected} transparent={!isCategoriesSelected} onClick={() => router.setPath(Paths.home)} icon={icons.categories}>Categories</Button>
				<Button light={isFilesSelected} transparent={!isFilesSelected} onClick={() => router.setPath(Paths.file)} icon={icons.files}>Files</Button> </SLeft> <SMiddle>
				{/* <Text middle center bold>ID code: YourXRPlatform</Text> */}
			</SMiddle>
			<SRight>
				<MenuAppVersion />
				<Button transparent onClick={() => logOutHandler()} icon={icons.logout}>Logout</Button>
			</SRight>
		</STopBar>
	)
}

/* STYLES */
const SBackground = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .5;
	z-index: 1;

    cursor: pointer;
`

const SVersionPopUp = styled.div`
    background: #002A42;
	position: absolute;
	z-index: 1;
	width: 180px;
    height: 140px;
    border-radius: 6px;
    color: white;
    padding: 10px 20px 10px 20px;
`

const SColumnsLive = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 2rem;
	margin: 5px 0 15px 0;
`

const SColumnsTest = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
`

const STopBar = styled.div`
    background: linear-gradient(0deg, ${p => p.theme.colors.primary} 0%, ${p => p.theme.colors.alternative} 100%);
    display: grid;
    padding: 0 2em;
    grid-template-columns: min-content auto min-content;
`

const SLeft = styled.div`
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${p => p.theme.gridGap};
    width: min-content;
`

const SLogo = styled.div`
    width: 10rem;
    cursor: pointer;
`

const SMiddle = styled.div`

`

const SRight = styled.div`
    display: grid; 
    place-items: center;
	grid-template-columns: 1fr 1fr min-content;
`

const SWrapperMenuVersion = styled.div`
    padding-right: 20px;
`

