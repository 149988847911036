import { useState, useEffect } from 'react'
import {useStore } from '../../hooks'

import styled, { css } from 'styled-components'
import icons from '../../assets/icons'
import { Text, SVG, InputField, Button } from '../../components/UI'
import Theme from '../../theme'
import Checkbox from "react-custom-checkbox";
import { urls } from '../../brand'

export default function StepTwoWizard() {
    const store = useStore()

    const [promotedToLiveAndroid, setPromotedToLiveAndroid] = useState(false)
    const [promotedToLiveIos, setPromotedToLiveIos] = useState(false)
    const [storeReviewAndroid, setStoreReviewAndroid] = useState(false)
    const [storeReviewIos, setStoreReviewIos] = useState(false)

    useEffect(() => {
        const allSelected = store.state?.config?.testVersion.promotedToLiveAndroid && store.state?.config?.testVersion.promotedToLiveIos && store.state?.config?.testVersion.storeReviewAndroid && store.state?.config?.testVersion.storeReviewIos
        const configAux = { ...store.state?.config }

        if (allSelected) {
            configAux.testVersion.status = 'publishing-success'
            store.dispatch({ type: 'set-config', payload: configAux })
        } else {
            configAux.testVersion.status = 'publishing-inprogress'
            store.dispatch({ type: 'set-config', payload: configAux })
        }
	}, [store.state?.config?.testVersion.promotedToLiveAndroid,
        store.state?.config?.testVersion.promotedToLiveIos,
        store.state?.config?.testVersion.storeReviewAndroid,
        store.state?.config?.testVersion.storeReviewIos])

    useEffect(() => {
        if (store.state?.config?.testVersion) {
            setPromotedToLiveAndroid(store.state?.config?.testVersion.promotedToLiveAndroid)
            setPromotedToLiveIos(store.state?.config?.testVersion.promotedToLiveIos)
            setStoreReviewAndroid(store.state?.config?.testVersion.storeReviewAndroid)
            setStoreReviewIos(store.state?.config?.testVersion.storeReviewIos)
        }
	}, [])

    useEffect(() => {
        const testVersionAux = {
            ...store.state?.config?.testVersion,
            promotedToLiveAndroid, 
            promotedToLiveIos, 
            storeReviewAndroid, 
            storeReviewIos, 
        }

        store.dispatch({ type: 'set-config', payload: {
                ...store.state?.config,
                testVersion: testVersionAux
            }
        })
	}, [promotedToLiveAndroid, promotedToLiveIos, storeReviewAndroid, storeReviewIos])

    return (
        <>
        <SStepGrid>
            <div>
                <Text dark bold style={{height:'auto'}} center>Version String</Text>
                <Text dark bold center style={{fontSize: '25px', paddingTop: '5px'}}>{store.state?.config?.testVersion.version}</Text>

                <Text dark bold style={{height:'auto'}} center>Steps</Text>
            </div>

            <div>
                <SHeader>
                    <Text dark bold>Stores</Text>
                    <Text medium bold center>Promoted to Live Version</Text>
                    <Text medium bold center>Store Review Completed</Text>
                </SHeader>
                    
                <SVersionList>
                    <VersionContainer>
                        <div style={{margin: '30px', display:'flex', textAlign: 'left'}}>
                            <SVG contain dark image={icons.android} style={{width:'40px', height:'40px'}} />
                            <div style={{margin: 'auto', marginLeft:'30px'}}>
                                <Text medium bold style={{height:'auto'}}>Play console</Text>
                                <Text dark bold>cloudbuild270035596</Text>
                                <a href={urls.googleStore} target="_blank">Go To Store</a> →
                            </div>
                        </div>
                        <Checkbox
                            checked={promotedToLiveAndroid}
                            icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', margin: 'auto', background: Theme.colors.light}} dark/>}
                            borderColor={Theme.colors.primary}
                            borderRadius={3}
                            size={18}
                            onChange={(value) => setPromotedToLiveAndroid(value)}
                            style={{display: 'block', width: '20px', height: '20px', background: promotedToLiveAndroid ? Theme.colors.primary :  Theme.colors.light}}
                        />
                        <Checkbox
                            checked={promotedToLiveIos}
                            icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', margin: 'auto', background: Theme.colors.light}} dark/>}
                            borderColor={Theme.colors.primary}
                            borderRadius={3}
                            size={18}
                            onChange={(value) => setPromotedToLiveIos(value)}
                            style={{display: 'block', width: '20px', height: '20px', background: promotedToLiveIos ? Theme.colors.primary :  Theme.colors.light}}
                        />
                    </VersionContainer>

                    <VersionContainer>
                        <div style={{margin: '30px', display:'flex', textAlign: 'left'}}>
                            <SVG contain dark image={icons.ios} style={{width:'40px', height:'40px'}} />
                            <div style={{margin: 'auto', marginLeft:'30px'}}>
                                <Text medium bold style={{height:'auto'}}>App Store Connect</Text>
                                <Text dark bold>cloudbuild270035596</Text>
                                <a href={urls.iosStore} target="_blank">Go To Store</a> →
                            </div>
                        </div>
                        <Checkbox
                            checked={storeReviewAndroid}
                            icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', margin: 'auto', background: Theme.colors.light}} dark/>}
                            borderColor={Theme.colors.primary}
                            borderRadius={3}
                            size={18}
                            onChange={(value) => setStoreReviewAndroid(value)}
                            style={{display: 'block', width: '20px', height: '20px', background: storeReviewAndroid ? Theme.colors.primary :  Theme.colors.light}}
                        />
                        <Checkbox
                            checked={storeReviewIos}
                            icon={<SVG contain image={icons.check} style={{width:'10px', height:'10px', margin: 'auto', background: Theme.colors.light}} dark/>}
                            borderColor={Theme.colors.primary}
                            borderRadius={3}
                            size={18}
                            onChange={(value) => setStoreReviewIos(value)}
                            style={{display: 'block', width: '20px', height: '20px', background: storeReviewIos ? Theme.colors.primary :  Theme.colors.light}}
                        />
                    </VersionContainer>

                </SVersionList>

                <SStepContainer>
                    <Text medium bold style={{marginBottom: '6px'}}>1. Promote the Test Versions to a new Live Version in each store.</Text>
                    <Text medium bold style={{marginBottom: '6px'}}>2. Stores will review the new update and let you know when ready.</Text>
                    <Text medium bold style={{marginBottom: '6px'}}>3. When the reviews are ready and approved, check them above.</Text>
                    <Text medium bold style={{marginBottom: '6px'}}>4. Press Next button.</Text>
                </SStepContainer>

            </div>

        </SStepGrid>
        </>
    )
}

/*STYLES*/
const SVersionList = styled.div`
    overflow: auto;
    max-height: 500px;
`

const SStepGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 2rem;
`

const SStepContainer = styled.div`
    margin: 0;
    position: absolute;
    padding-top: 25px;
`

const VersionContainer = styled.div`
    background: #edededed;
    grid-template-columns: 3fr 2fr 1fr;
    display: grid;
    grid-gap: 2rem;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    height: 130px;
    margin-top: 10px;
    text-align: right;
`

const SHeader = styled.div`
    grid-template-columns: 1.2fr 1fr 1fr;
    display: grid;
    grid-gap: 2rem;
`