import React from 'react'
import styled from 'styled-components'

import { Text, SVG } from './UI'
import icons from '../assets/icons'

const SMobileAlert = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: ${p => p.theme.colors.darkLight};
    z-index: 10;
    display: grid;
    place-items: center;
    padding: ${p => p.theme.padding};
`

const SPanel = styled.div`
    background-color: ${p => p.theme.colors.light};
    padding: ${p => p.theme.padding};
    display: grid;
    border-radius: ${p => p.theme.borderRadius};
    grid-gap: ${p => p.theme.gridGap};
    justify-items: center;
`

const SIcon = styled.div`
    height: 5rem;
    width: 5rem;
`

export default function MobileAlert() {
    return (
        <SMobileAlert>
            <SPanel>
                <SIcon><SVG contain image={icons.warning} primary /></SIcon>
                <Text center primary bold>Mobile version currently not available</Text>
                <Text center dark bold>Please switch to a desktop computer to use this feature.</Text>
            </SPanel>
        </SMobileAlert>
    )
}
