import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'

import EditExperience from '../panels/EditExperience'
import Confirmation from '../panels/Confirmation'
import { useAwakeBool, useRouter, useStore, usePopupPanel } from '../hooks'
import { SLayout, STitle, EditButtons } from './'
import { SVG, Text, Image, RoundIconButton } from '../components/UI'
import Unity from '../components/Unity'
import { pathFunctions } from '../utility'
import icons from '../assets/icons'
import { Paths } from '../context/RouterContext'

const SDetails = styled.div`
    position: relative;
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-rows: min-content auto;
    box-sizing: border-box; 
    overflow: hidden;
`

const SScroll = styled.div`
    overflow: auto;
`

const SPanel = styled.div`
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.theme.colors.primary};
    display: grid;
    grid-gap: .1rem;
    overflow: hidden;
`

const SImage = styled.div`
    height: 15rem;
    background-color: ${p => p.theme.colors.light};
`

const SText = styled.div`
    background-color: ${p => p.theme.colors.light};
    padding: ${p => p.theme.padding};
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

const SName = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
`

const BackButton = styled.div`
    position: absolute;
    width: ${p => p.theme.minSize};
    height: ${p => p.theme.minSize};
    top: 2rem;
    left: 5rem;
`

export default function Experience() {
    const awake = useAwakeBool()
    const store = useStore()
    const router = useRouter()
    const popupPanel = usePopupPanel()

    const experience = store?.state?.experiences ? store?.state?.experiences[router.getParam('experience')] ?? {} : {}
    const selectedCategory = store?.state?.categories ? store?.state?.categories[router.getParam('category')] ?? {} : {}

    function editHandler(): void {
        popupPanel.setContent(<EditExperience
            onSave={(experience => {
                popupPanel.setActive(false)
            })}
            experience={experience}
        />)
        popupPanel.setActive(true)
    }

    function deleteHandler(): void {
        popupPanel.setContent(<Confirmation
            title='Delete'
            icon={icons.delete}
            description={`Are you sure you want to delete ${experience.name}?`}
            subDescription='This action cannot be undone.'
            cancel='Cancel'
            confirm='Delete'
            confirmHandler={() => {
                delete selectedCategory.experiences[experience.id]
                store.dispatch({ type: 'set-category', payload: selectedCategory })
                store.dispatch({ type: 'delete-experience', payload: experience })
                router.setPath(Paths.home)
            }}
        />)
        popupPanel.setActive(true)
    }

    return (
        <SLayout awake={awake}>
            <BackButton><RoundIconButton onClick={() => router.setPath(Paths.home)} icon={icons.arrow} /></BackButton>
            <SDetails>
                <STitle>
                    <SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={icons.details} contain dark />
                    <Text middle bold big dark>Details</Text>
                </STitle>
                <SScroll>
                    <SPanel>
                        <SImage>
                            <Image contain url={pathFunctions.getExperienceThumbnail(experience)} version={experience.imageVersion}/>
                        </SImage>
                        <SText>
                            <SName>
                                <Text bold dark big>{experience.name}</Text>
                                <EditButtons
                                    editHandler={editHandler}
                                    deleteHandler={deleteHandler}
                                />
                            </SName>
                            <Text dark>{experience.description}</Text>
                        </SText>
                    </SPanel>
                </SScroll>
            </SDetails>
            <SDetails>
                <STitle>
                    <SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={icons.experiences} contain dark />
                    <Text middle bold big dark>Preview</Text>
                </STitle>
                <Unity sceneId={experience.sceneId} />
            </SDetails>
        </SLayout>
    )
}
