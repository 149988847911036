import React from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Context.
import { RouterContext, Paths } from './context/RouterContext'
import { AuthContext } from './context/AuthContext'
import { EventsContext } from './context/EventsContext'
import { PopupPanelContext } from './context/PopupPanelContext'
import { WizardContext } from './context/WizardContext'
import { StoreContext } from './store'

// Pages.
import Login from './pages/Login'
import Home from './pages/Home'
import Files from './pages/Files'
import Experience from './pages/Experience'

// Layout.
import TopBar from './components/TopBar'

// Overlay.
import PopupPanel from './components/PopupPanel'

// Mobile Alert TEMP
import { isMobile } from 'react-device-detect'
import MobileAlert from './components/MobileAlert'
import { ToastContainer } from 'react-toastify'
// Mobile Alert TEMP

const SLayout = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;

    display: grid;
    grid-template-rows: 3.4rem auto;
    background-color: ${p => p.theme.colors.background};
    overflow: hidden;
`

function App() {
    return (
        <Context>
            <Switch>
                <Route path={Paths.login}><Login /></Route>

                <SLayout>
                    <TopBar />
                    <Route path={Paths.home} exact><Home /></Route>
                    <Route path={Paths.file}><Files /></Route>
                    <Route path={Paths.experience}><Experience /></Route>
                </SLayout>
            </Switch>

            <PopupPanel />

            {isMobile && <MobileAlert />}
            <ToastContainer />
        </Context>
    )
}

function Context({ children }) {
    return (
        <Router>
            <EventsContext>
                <RouterContext>
                    <StoreContext>
                        <AuthContext>
                            <PopupPanelContext>
                                <WizardContext>
                                    {children}
                                </WizardContext>
                            </PopupPanelContext>
                        </AuthContext>
                    </StoreContext>
                </RouterContext>
            </EventsContext>
        </Router>
    )
}

export default App
