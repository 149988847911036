import firebase from './'

// Login to firebase auth with email and password.
export const logIn = (email, password) => new Promise(async (resolve, reject) => {
	try {
		await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		const response = await firebase.auth().signInWithEmailAndPassword(email, password)
		resolve(response)
	}

	catch (error) { reject(error) }
})

// Logout from firebase auth.
export const logOut = () => new Promise(async (resolve, reject) => {
	try {
		const response = await firebase.auth().signOut()
		resolve(response)
	} catch (error) { reject(error) }
})

// Register to firebase auth.
export const register = (email, password) => new Promise(async (resolve, reject) => {
	try {
		const response = await firebase.auth().createUserWithEmailAndPassword(email, password)
		resolve(response)
	} catch (error) { reject(error) }
})

// Listen to auth user state changes.
export const trackAuthState = observer => {
	return firebase.auth().onAuthStateChanged(observer)
}

export const sendResetPasswordEmail = email => new Promise((resolve, reject) => {
	firebase.auth().sendPasswordResetEmail(email).then(resolve).catch(e => reject(e.message));
})

// Get current logged user from cache.
export const getCurrentUser = () => firebase.auth().currentUser