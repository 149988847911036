import 'styled-components'
import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
    export interface DefaultTheme {
        borderRadius: string,
        padding: string,
        gridGap: string,
        sideMargins: string,
        minSize: string,
        lineSize: string,
        shadow: string,

        colors: {
            primary: string,
            primaryLight: string,
            alternative: string,
            light: string,
            darkLight: string,
            background: string,
            dark: string,
            medium: string
        },

        fontSize: {
            title: string,
            subTitle: string,
            text: string
        }
    }
}

const Theme: DefaultTheme = {
    borderRadius: '.3rem',
    padding: '1rem',
    gridGap: '1rem',
    sideMargins: '.6rem',
    minSize: '2.3rem',
    lineSize: '.05rem',
    shadow: '0 .2rem .2rem rgba(0, 0, 0, .1)',

    colors: {
        primary: '#1C8150',
        primaryLight: '#1c815059',
        alternative: '#349061',
        light: '#ffffff',
        darkLight: '#F0F0F0',
        background: '#FAFAFA',
        dark: '#00260e',
        medium: '#C7C5C5'
    },
    fontSize: {
        title: '1.3rem',
        subTitle: '1.1rem',
        text: '1rem'
    }
}

export default Theme;