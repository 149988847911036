import { useState, useEffect } from "react";
import { useStore, useWizard } from "../../hooks";

import styled from "styled-components";
import icons from "../../assets/icons";
import { Text, SVG } from "../../components/UI";
import Loading from "../../components/Loading";
import { getBuild, startBuild } from "../../api/unityCloud";
import {
    getSettingsFile,
    getSubmoduleLastCommit,
    updateSettingsFile,
    updateSubmodule,
} from "../../api/gitlab";

export default function StepTwoWizard() {
    const store = useStore();
    const [loading, setLoading] = useState(true);
    const [buildStatusAndroid, setBuildStatusAndroid] = useState("progress");
    const [buildStatusIos, setBuildStatusIos] = useState("progress");
    const [buildIdAndroid, setBuildIdAndroid] = useState("");
    const [buildIdIos, setBuildIdIos] = useState("");
    const wizard = useWizard();

    async function processGitLab() {
        try {
            const responseSubmoduleLastCommit = await getSubmoduleLastCommit();

            if (responseSubmoduleLastCommit.status !== 200) {
                console.log(responseSubmoduleLastCommit);
                alert("Error get latest commit");
                return;
            }

            const submoduleLastCommit =
                await responseSubmoduleLastCommit.json();

            if (!submoduleLastCommit?.commit?.id) {
                console.log(submoduleLastCommit);
                alert("Error get latest commit");
                return;
            }

            const responseUpdateCommit = await updateSubmodule(
                submoduleLastCommit.commit.id
            );

            if (responseUpdateCommit.status !== 200) {
                if (responseUpdateCommit.status === 400)
                    console.log("Submodule already up to date");
                else {
                    console.log(responseUpdateCommit);
                    alert("Error updating commit reference");
                    return;
                }
            }

            const responseGetFile = await getSettingsFile();

            if (responseGetFile.status !== 200) {
                console.log(responseGetFile);
                alert("Error getting file version");
                return;
            }

            const resultGetFile = await responseGetFile.json();

            let fileString = atob(resultGetFile.content);

            const getVersion = (yaml: string): string => {
                const key = "bundleVersion: ";
                const from = yaml.indexOf(key) + key.length;
                const to = yaml.indexOf("\n", from);

                return yaml.substring(from, to);
            };

            const liveVersion = getVersion(fileString);
            const localVersion = store.state?.config?.testVersion.version;

            if (liveVersion != localVersion) {
                const newFile = fileString.replace(
                    /(bundleVersion:).*(\s)/,
                    "bundleVersion: " +
                        store.state?.config?.testVersion.version +
                        "\n"
                );

                const responseUpdateFile = await updateSettingsFile(
                    newFile,
                    store.state?.config?.testVersion.version
                );

                if (responseUpdateFile.status !== 200) {
                    console.log(responseUpdateFile);
                    alert("Error updating file version");
                    return;
                }
            } else console.log("Settings File Up to date");

            // Now start processing Unity build.
            processUnityCloud();
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    async function processUnityCloud() {
        try {
            const [responseStartBuildAndroid, responseStartBuildIos] =
                await Promise.all([startBuild("android"), startBuild("ios")]);

            let buildAndroidId, buildIosId;

            if (responseStartBuildAndroid.status !== 200) {
                console.log(responseStartBuildAndroid);
                setBuildStatusAndroid("failure");
            } else {
                const resultStartBuildAndroid =
                    await responseStartBuildAndroid.json();
                buildAndroidId = resultStartBuildAndroid[0].build;
            }

            if (responseStartBuildIos.status !== 200) {
                console.log(responseStartBuildIos);
                setBuildStatusIos("failure");
            } else {
                const resultStartBuildIos = await responseStartBuildIos.json();
                buildIosId = resultStartBuildIos[0].build;
            }

            // console.log(buildAndroidId, buildIosId)

            if (buildAndroidId && buildIosId) {
                // console.log('Build IDs ready')
                setBuildIdAndroid(buildAndroidId);
                setBuildIdIos(buildIosId);
            }

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (
            store.state?.config.testVersion.cloudBuildIdAndroid ||
            store.state?.config.testVersion.cloudBuildIdIOS
        ) {
            const interval = setInterval(() => {
                setBuildIdAndroid(
                    store.state?.config.testVersion.cloudBuildIdAndroid
                );
                setBuildIdIos(store.state?.config.testVersion.cloudBuildIdIOS);

                if (store.state?.config.testVersion.status == "build-success") {
                    setBuildStatusIos("success");
                    setBuildStatusAndroid("success");
                    setLoading(false);
                    return;
                }
                getBuild(
                    "android",
                    store.state?.config.testVersion.cloudBuildIdAndroid
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (
                            result.buildStatus === "success" ||
                            result.buildStatus === "failure"
                        )
                            setBuildStatusAndroid(result.buildStatus);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
                getBuild("ios", store.state?.config.testVersion.cloudBuildIdIOS)
                    .then((response) => response.json())
                    .then((result) => {
                        if (
                            result.buildStatus === "success" ||
                            result.buildStatus === "failure"
                        )
                            setBuildStatusIos(result.buildStatus);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [
        store.state?.config.testVersion.cloudBuildIdAndroid,
        store.state?.config.testVersion.cloudBuildIdIOS,
    ]);

    useEffect(() => {
        if (wizard.state.triggerBuilds) {
            // console.log('Start GitLab process')
            processGitLab();
            wizard.set((p) => ({ ...p, triggerBuilds: false }));
        }
    }, [wizard.state.triggerBuilds]);

    useEffect(() => {
        if (buildIdIos && buildIdAndroid) {
            const configAux = { ...store.state?.config };
            configAux.testVersion.cloudBuildIdAndroid = buildIdAndroid;
            configAux.testVersion.cloudBuildIdIOS = buildIdIos;
            store.dispatch({ type: "set-config", payload: configAux });
        }
    }, [buildIdIos, buildIdAndroid]);

    useEffect(() => {
        if (store.state?.config.testVersion.status === "build-success") {
            setBuildStatusAndroid("success");
            setBuildStatusIos("success");
        }
    }, [store.state?.config.testVersion.status]);

    useEffect(() => {
        const configAux = { ...store.state?.config };

        if (buildStatusIos === "failure" || buildStatusAndroid === "failure") {
            configAux.testVersion.status = "build-failure";
        } else if (
            buildStatusIos === "success" &&
            buildStatusAndroid === "success"
        ) {
            configAux.testVersion.status = "build-success";
        }

        store.dispatch({ type: "set-config", payload: configAux });
    }, [buildStatusIos, buildStatusAndroid]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <SStepGrid>
                        <div>
                            <Text dark bold style={{ height: "auto" }} center>
                                Version String
                            </Text>
                            <Text
                                dark
                                bold
                                center
                                style={{ fontSize: "25px", paddingTop: "5px" }}
                            >
                                {store.state?.config?.testVersion.version}
                            </Text>
                        </div>

                        <div>
                            <Text dark bold style={{ height: "auto" }}>
                                Builds
                            </Text>
                            <SVersionList>
                                <VersionContainer>
                                    <div
                                        style={{
                                            margin: "0",
                                            display: "flex",
                                            textAlign: "left",
                                            width: "226px",
                                        }}
                                    >
                                        <SVG
                                            contain
                                            dark
                                            image={icons.android}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                margin: "auto",
                                            }}
                                        />
                                        <div
                                            style={{
                                                margin: "auto",
                                                marginLeft: "30px",
                                            }}
                                        >
                                            <Text
                                                medium
                                                bold
                                                style={{ height: "auto" }}
                                            >
                                                Android Build
                                            </Text>
                                            <Text dark bold>
                                                {buildIdAndroid}
                                            </Text>
                                        </div>
                                    </div>

                                    {buildStatusAndroid === "progress" && (
                                        <a
                                            href={`https://dashboard.unity3d.com/organizations/4672950707013/projects/a624d321-f37b-4743-aec6-6f185f74cacb/cloud-build/buildtargets/alberta-android/builds/${buildIdAndroid}/log?compact=true&withHtml=true`}
                                            style={{ marginTop: "36px" }}
                                            target="_blank"
                                        >
                                            View Progress
                                        </a>
                                    )}
                                    {buildStatusAndroid === "failure" && (
                                        <a
                                            href={`https://dashboard.unity3d.com/organizations/4672950707013/projects/a624d321-f37b-4743-aec6-6f185f74cacb/cloud-build/buildtargets/alberta-android/builds/${buildIdAndroid}/log?compact=true&withHtml=true`}
                                            style={{ marginTop: "36px" }}
                                            target="_blank"
                                        >
                                            Open Bug Report
                                        </a>
                                    )}
                                    {buildStatusAndroid === "success" && (
                                        <span></span>
                                    )}

                                    <SVG
                                        contain
                                        image={
                                            buildStatusAndroid === "failure"
                                                ? icons.warningR
                                                : buildStatusAndroid ===
                                                  "success"
                                                ? icons.checkBox
                                                : icons.history
                                        }
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                            margin: "auto",
                                        }}
                                        primary
                                    />
                                </VersionContainer>

                                <VersionContainer>
                                    <div
                                        style={{
                                            margin: "0",
                                            display: "flex",
                                            textAlign: "left",
                                            width: "200px",
                                        }}
                                    >
                                        <SVG
                                            contain
                                            dark
                                            image={icons.ios}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                margin: "auto",
                                            }}
                                        />
                                        <div
                                            style={{
                                                margin: "auto",
                                                marginLeft: "30px",
                                            }}
                                        >
                                            <Text
                                                medium
                                                bold
                                                style={{ height: "auto" }}
                                            >
                                                iOS Build
                                            </Text>
                                            <Text dark bold>
                                                {buildIdIos}
                                            </Text>
                                        </div>
                                    </div>

                                    {buildStatusIos === "progress" && (
                                        <a
                                            href={`https://dashboard.unity3d.com/organizations/4672950707013/projects/a624d321-f37b-4743-aec6-6f185f74cacb/cloud-build/buildtargets/alberta-ios/builds/${buildIdIos}/log?compact=true&withHtml=true`}
                                            style={{ marginTop: "36px" }}
                                            target="_blank"
                                        >
                                            View Progress
                                        </a>
                                    )}
                                    {buildStatusIos === "failure" && (
                                        <a
                                            href={`https://dashboard.unity3d.com/organizations/4672950707013/projects/a624d321-f37b-4743-aec6-6f185f74cacb/cloud-build/buildtargets/alberta-ios/builds/${buildIdIos}/log?compact=true&withHtml=true`}
                                            style={{ marginTop: "36px" }}
                                            target="_blank"
                                        >
                                            Open Bug Report
                                        </a>
                                    )}
                                    {buildStatusIos === "success" && (
                                        <span></span>
                                    )}

                                    <SVG
                                        contain
                                        image={
                                            buildStatusIos === "failure"
                                                ? icons.warningR
                                                : buildStatusIos === "success"
                                                ? icons.checkBox
                                                : icons.history
                                        }
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                            margin: "auto",
                                        }}
                                        primary
                                    />
                                </VersionContainer>
                            </SVersionList>
                        </div>
                    </SStepGrid>

                    <SFooter>
                        <Text medium bold center>
                            If any build is failed, discard version and start
                            over.
                        </Text>
                    </SFooter>
                </>
            )}
        </>
    );
}

/*STYLES*/
const SVersionList = styled.div`
    overflow: auto;
    max-height: 400px;
`;

const SStepGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 2rem;
`;

const TextImage = styled.div`
    display: flex;
    justify-content: center;
    grid-gap: 0.5rem;
    margin: auto;
`;

const VersionContainer = styled.div`
    background: #edededed;
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    grid-gap: 2rem;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    height: 100px;
    margin-top: 10px;
    text-align: right;
`;

const SFooter = styled.div`
    position: absolute;
    bottom: 100px;
    width: 100%;
    right: 0px;
`;
