import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { key } from 'firebase-key'

import Files from './EditExperience/Files'

import { Text, Button, InputField, SVG, TextArea } from '../components/UI'
import { SList, SThumbnail, DropImage } from './'
import icons from '../assets/icons'
import { IExperience, IScene } from '../store'
import { useStore } from '../hooks'
import { pathFunctions } from '../utility'
import { deleteFile, uploadImage } from '../firebase/storage'
import Loading from '../components/Loading'

const SExperience = styled.div`
    width: 50vw;
    overflow: hidden;
    position: relative;
    transition: .3s;
`

const SEdit = styled.div<{ active: boolean }>`
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-gap: ${p => p.theme.gridGap};

    transition: .5s;
    opacity: ${p => p.active ? 1 : 0};
    ${p => !p.active && css`
        transform: translateX(-100%);
    `}
`

const STitle = styled.div`
    display: grid;
    margin: auto;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-columns: ${p => p.theme.minSize} auto;
`

const SMiddle = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: ${p => p.theme.gridGap};
`

export default function EditExperience(props: { experience?: IExperience, onSave(experience: IExperience): void }) {
    const store = useStore()
    const [experience, setExperience] = useState<IExperience>(props.experience ?? { id: key(), imageVersion: 1 })
    const [state, set] = useState<{ view: 'edit' | 'file' | 'file-test' }>({
        view: 'edit'
    })
    const [enableVersion, setEnableVersion] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [image, setImage] = useState<File>()
    const [deleteImage, setDeleteImage] = useState(false)

    const incrementVersion = () => setExperience(p => ({...p, imageVersion: p.imageVersion + 1 }))

    async function saveHandler(): Promise<void> {
        if (!experience) set(p => ({ ...p, view: 'file' }))
        else {
            const url = pathFunctions.getExperienceThumbnail(experience)

            if (image) {
                setIsLoading(true)
                await uploadImage(image, url)
                setIsLoading(false)
            }
            
            else if (deleteImage) {
                setIsLoading(true)
                await deleteFile(url)
                setIsLoading(false)
            }

            store.dispatch({ type: 'set-experience', payload: experience })
            props.onSave(experience)
        }
    }

    function imageSelectHandler(image: File): void {
        setImage(image)
        setDeleteImage(false)
        incrementVersion()
    }

    function deleteImageHandler(): void {
        setDeleteImage(true)
        incrementVersion()
    }

    useEffect(() => {
        const cloudBuildIdAndroid = store.state?.config?.testVersion.cloudBuildIdAndroid
        const cloudBuildIdIOS = store.state?.config?.testVersion.cloudBuildIdIOS
		if(cloudBuildIdIOS || cloudBuildIdAndroid) {
			setEnableVersion(false)
		}
	}, [store.state?.config?.testVersion])

    if (isLoading) return (
        <SExperience style={{height: '15rem'}}>
            <Loading />
        </SExperience>
    )

    return (
        <SExperience>
            <SEdit active={state.view === 'edit'}>
                <STitle>
                    <SVG dark contain image={icons.experiences} style={{ height: '100%', width: '100%' }} />
                    <Text dark bold>{props.experience ? 'Edit' : 'New'} Experience</Text>
                </STitle>
                <SMiddle>
                    <SList style={{ gridTemplateRows: 'min-content auto' }}>
                        <Text subTitle dark bold>Thumbnail</Text>
                        <SThumbnail>
                            <DropImage
                                storagePath={pathFunctions.getExperienceThumbnail(experience)}
                                imageVersion={experience.imageVersion}
                                onSelect={image => imageSelectHandler(image)}
                                onDelete={() => deleteImageHandler()}
                            />
                        </SThumbnail>
                    </SList>
                    <SList>
                        <Text subTitle dark bold>Name</Text>
                        <InputField
                            value={experience.name}
                            placeholder='Experience Name'
                            onChange={v => setExperience(e => ({ ...e, name: v }))}
                        />
                        <Text subTitle dark bold>Description</Text>
                        <TextArea
                            rows={6}
                            value={experience.description}
                            placeholder='Experience Description'
                            onChange={v => setExperience(e => ({ ...e, description: v }))}
                        />
                    </SList>
                </SMiddle>
                    <div />
                    <Text subTitle dark bold big>Files</Text>
                    <SList>
                        <Text subTitle dark bold>Live</Text>
                        <SelectedFile 
                            clickHandler={() => set(p => ({ ...p, view: 'file' }))} 
                            deleteHandler={() => setExperience({...experience, sceneId:''})} 
                            selected={experience?.sceneId && store?.state?.scenes[experience.sceneId]} />

                        <Text subTitle dark bold>Test</Text>
                        <SelectedFile 
                            clickHandler={() => {enableVersion ? set(p => ({ ...p, view: 'file-test' })) : alert('Build in progress')}} 
                            deleteHandler={() => {enableVersion ? setExperience({...experience, sceneIdTest:''}) : alert('Build in progress')}} 
                            selected={experience?.sceneIdTest && store?.state?.scenes[experience.sceneIdTest]} 
                            disabled={!enableVersion}
                        />
                    </SList>
                    
                    <Button onClick={() => saveHandler()} icon={experience ? '' : icons.add}> {experience ? 'Done' : 'Add'}</Button>
            </SEdit>

            <Files
                active={state.view === 'file'}
                backHandler={() => { set(p => ({ ...p, view: 'edit' })) }}
                selectHandler={sceneId => {
                    setExperience(p => ({ ...p, sceneId: sceneId }))
                    set(p => ({ ...p, view: 'edit' }))
                }}
            />

            <Files
                active={state.view === 'file-test'}
                backHandler={() => { set(p => ({ ...p, view: 'edit' })) }}
                selectHandler={sceneIdTest => {
                    setExperience(p => ({ ...p, sceneIdTest: sceneIdTest }))
                    set(p => ({ ...p, view: 'edit' }))
                }}
            />
        </SExperience>
    )
}

const SSelectedFile = styled.div<{ fileSelected: boolean }>`
    border: .15rem ${p => p.fileSelected ? 'solid' : 'dashed'} ${p => p.fileSelected ? p.theme.colors.primary : p.theme.colors.medium};
    display: grid;
    place-items: center;
    height: 2.5rem;

    transition: .5s;

    :hover {
        border: .15rem solid ${p => p.theme.colors.primary};
    }
`

const SSelectedFileRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: ${p => p.theme.gridGap};
`

const SGridFileSelect = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`

function SelectedFile(props: { clickHandler(): void, selected: IScene , disabled?: boolean, deleteHandler(): void }) {
    return (
        <SSelectedFile fileSelected={props?.selected != null}  style={props?.disabled? { background:'#d4d4d4', cursor: 'no-drop'} : {}}>
             {props?.selected ? 
             <SSelectedFileRow>
                <SGridFileSelect>
                    <SVG primary contain image={icons.files} />
                    <Text primary bold oneline style={{width:'100px'}}>{props.selected.displayName.length > 15 ? props.selected.displayName.substring(0,15) + "..." : props.selected.displayName}</Text>
                </SGridFileSelect>

                <Text medium bold oneline center>{'Version ' + props.selected.version}</Text>
                <Text medium bold oneline center>{new Date(props.selected.uploadTimestamp).toDateString()}</Text>
                <span></span>
                <SGridFileSelect>
                    <SVG contain image={icons.replace} dark onClick={() => props.clickHandler()} style={!props.disabled ? {cursor: 'pointer'} : {}}/>
                    <SVG contain image={icons.delete} dark onClick={() => props.deleteHandler()} style={!props.disabled ? {cursor: 'pointer'}: {}}/>
                </SGridFileSelect>
            </SSelectedFileRow> 
            :
            <SSelectedFileRow style={!props.disabled ? {cursor: 'pointer'}: {}} onClick={() => props.clickHandler()}>
                <span></span>
                <span></span>
                <SGridFileSelect>
                    <SVG primary contain image={icons.attach} />
                    <Text primary bold oneline>Link file</Text>
                </SGridFileSelect>
                <span></span>
                <span></span>
            </SSelectedFileRow>
            }
        </SSelectedFile>
    )
}
