import React, { useState, useEffect} from 'react'
import styled, { css } from 'styled-components'
import { usePopupPanel, useStore, useWizard } from '../../hooks'
import { IConfig, IScene} from '../../store'
import icons from '../../assets/icons'
import { Text, SVG, Button } from '../../components/UI'
import StepZeroWizard from './StepZeroWizard'
import StepOneWizard from './StepOneWizard'
import StepTwoWizard from './StepTwoWizard'
import StepThreeWizard from './StepThreeWizard'
import StepFourWizard from './StepFourWizard'
import StepFiveWizard from './StepFiveWizard'
import Loading from '../../components/Loading'
import { cancelBuild } from '../../api/unityCloud'

export default function ContainerWizard() {
    const store = useStore()
    const [step, setStep] = useState(0)
    const wizard = useWizard()
    const popupPanel = usePopupPanel()
    const [loading, setLoading] = useState(true)

    function discardVersion () {
        const actualLiveVersion = store.state?.config.liveVersion
        const watermarkLink = store.state?.config.watermarkLink
        const watermarkOn = store.state?.config?.watermarkOn ?? false

        cancelBuild('android')
        cancelBuild('ios')

        store.dispatch({ type: 'set-config', payload: {
                liveVersion: actualLiveVersion,
                minAppVersionAndroid: actualLiveVersion,
                minAppVersionIOS: actualLiveVersion,
                watermarkLink: watermarkLink,
                watermarkOn: watermarkOn,
                testVersion: {
                    version : '',
                    status : '',
                    cloudBuildIdAndroid : '',
                    cloudBuildIdIOS : '',
                    errorMessage : '',
                    experiencesToTest: [],
                    promotedToLiveAndroid: false,
                    promotedToLiveIos: false,
                    storeReviewAndroid: false,
                    storeReviewIos: false,
                }
            }
        })
        
        popupPanel.setActive(false)
    }

    function done() {
        popupPanel.setActive(false)
        
        for (let index in store.state?.config?.testVersion.experiencesToTest) {
            const experience = store?.state?.experiences[store.state?.config?.testVersion.experiencesToTest[index].id]
            const updatedExperience = { ...experience }
            updatedExperience.sceneId = store.state?.config?.testVersion.experiencesToTest[index].sceneIdTest
            updatedExperience.sceneIdTest = ''
            store.dispatch({ type: 'set-experience', payload: updatedExperience })
        }

        const configAux = { ...store.state?.config }
        configAux.liveVersion = store.state?.config?.testVersion.version
        configAux.minAppVersionAndroid = configAux.liveVersion
        configAux.minAppVersionIOS = configAux.liveVersion
        configAux.testVersion = {
            version : '',
            status : '',
            cloudBuildIdAndroid : '',
            cloudBuildIdIOS : '',
            errorMessage : '',
            experiencesToTest: [],
            promotedToLiveAndroid: false,
            promotedToLiveIos: false,
            storeReviewAndroid: false,
            storeReviewIos: false,
        }

        store.dispatch({ type: 'set-config', payload: configAux })
    }

    useEffect(() => {
		if(store.state?.config?.testVersion) {
            if (store.state?.config?.testVersion.status === 'preparing') 
                setStep(1)
            else if (store.state?.config?.testVersion.status === 'build-inprogress' || store.state?.config?.testVersion.status === 'build-failure' || store.state?.config?.testVersion.status === 'build-success') 
                setStep(2)
            else if (store.state?.config?.testVersion.status === 'testing-inprogress' || store.state?.config?.testVersion.status === 'testing-success') 
                setStep(3)
            else if (store.state?.config?.testVersion.status === 'publishing-inprogress' || store.state?.config?.testVersion.status === 'publishing-success')
                setStep(4)
            else if (store.state?.config?.testVersion.status === 'done')
                setStep(5)
            else
                setStep(0)

            setLoading(false)
		}
	}, [store.state?.config?.testVersion])

    useEffect(() => {
        if(store.state?.config) {
            const configAux = { ...store.state?.config }
            if(step === 1)  configAux.testVersion.status = 'preparing'
            if(step === 2)  configAux.testVersion.status = 'build-inprogress'
            if(step === 3)  configAux.testVersion.status = 'testing-inprogress'
            if(step === 4)  configAux.testVersion.status = 'publishing-inprogress'
            if(step === 5)  configAux.testVersion.status = 'done'
    
            store.dispatch({ type: 'set-config', payload: configAux })
        }
	}, [step])

    function nextHandler() {
        const next = step + 1

        if (next == 2) { // Trigger builds.
            wizard.set(p => ({...p, triggerBuilds: true}))
        }

        setStep(next)
    }
    
    return (
        <SWizzard>
             {loading && <Loading />}
            <SHeader>
                <SVG style={{marginRight: '8px', height: '20px', width: '20px'}} contain image={icons.versions} dark/>
                <Text dark bold center>New Version</Text>
            </SHeader>

              { step > 0 && <SBreadcrumb>
                  <SBreadcrumbElement>
                    { step > 1 ? <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.check} primary/> : <span></span>}
                    <Text bold center  medium={step !== 1} dark={step === 1} style={{minWidth: 'max-content'}}>Test Version</Text>
                    <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.arrowRight} medium/>
                  </SBreadcrumbElement>

                  <SBreadcrumbElement>
                    { step > 2 ? <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.check} primary/> : <span></span>}
                    <Text bold center  medium={step !== 2} dark={step === 2}  style={{minWidth: 'max-content'}}>Cloud Building</Text>
                    <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.arrowRight} medium/>
                  </SBreadcrumbElement>

                  <SBreadcrumbElement>
                    { step > 3 ? <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.check} primary/> : <span></span>}
                    <Text bold center  medium={step !== 3} dark={step === 3}  style={{minWidth: 'max-content'}}>App Testing</Text>
                    <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.arrowRight} medium/>
                  </SBreadcrumbElement>

                  <SBreadcrumbElement>
                    { step > 4 ? <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.check} primary/> : <span></span>}
                    <Text bold center  medium={step !== 4} dark={step === 4}  style={{minWidth: 'max-content'}}>Store Publishing</Text>
                    <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.arrowRight} medium/>
                  </SBreadcrumbElement>

                  <SBreadcrumbElement>
                    { step > 5 ? <SVG style={{ margin:'auto', height: '10px', width: '10px'}} contain image={icons.check} primary/> : <span></span>}
                    <Text bold center  medium={step !== 5} dark={step === 5}  style={{minWidth: 'max-content'}}>Version Update</Text>
                  </SBreadcrumbElement>
                </SBreadcrumb> 
                }
            
            <SContainer>
                { step == 0 && <StepZeroWizard /> }
                { step == 1 && <StepOneWizard /> }
                { step == 2 && <StepTwoWizard /> }
                { step == 3 && <StepThreeWizard /> }
                { step == 4 && <StepFourWizard /> }
                { step == 5 && <StepFiveWizard /> }
            </SContainer>

            <SFooter>
                { step == 0 && <Button onClick={() => setStep(1)}>Start</Button>  }

                { step > 0 && step < 5 && 
                                <SButtonsContainer>
                                    <Button onClick={() => discardVersion()} style={{width:'100%'}} light>Discard Version</Button> 
                                    <Button 
                                        onClick={() => nextHandler()} style={{width:'100%'}} 
                                        disabled={
                                            store.state?.config?.testVersion.status === 'build-inprogress' ||
                                            store.state?.config?.testVersion.status === 'build-failure' ||
                                            store.state?.config?.testVersion.status === 'publishing-inprogress' ||
                                            store.state?.config?.testVersion.status === 'testing-inprogress'
                                        }>
                                        Next
                                    </Button>
                                </SButtonsContainer>
                }

                { step == 5 && <Button onClick={() => done()}>Finish</Button>  }

            </SFooter>
        </SWizzard>
    )
}

/*STYLES*/
const SWizzard = styled.div`
    width: 80vw;
    height: 94vh;
    max-width: 1400px;
    max-height: 800px;
    overflow: hidden;
    position: relative;
`

const SHeader = styled.div`
    display: flex;
    flex-flow: wrap-reverse;
    justify-content: center;
`

const SButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
`

const SFooter = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    right: 0px;
`

const SBreadcrumb = styled.div`
    max-width: 800px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin: auto;
    margin-top: 20px;
`

const SContainer = styled.div`
    justify-content: center;
    padding-top: 80px;
    max-width:1200px;
    margin: auto;
`

const SBreadcrumbElement = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`

