import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { usePopupPanel } from '../hooks'
import { SVG, Text } from './UI'
import icons from '../assets/icons'

const SContainer = styled.div<{ active: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: ${p => p.active ? 'grid' : 'none'};
    place-items: center;
`

const SBackground = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${p => p.theme.colors.dark};
    opacity: .5;

    cursor: pointer;
`

const SPopupPanel = styled.div<{ active: boolean }>`
    position: relative;
    padding: ${p => p.theme.padding};
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.theme.colors.light};
    z-index: 1;

    opacity: ${p => p.active ? 1 : 0};
    transition: .5s;
    ${p => !p.active && css`
        transform: translateY(2rem);
    `}
`

const SClose = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    :hover {
        * {
            background-color: ${p => p.theme.colors.primary};
        }
    }
    z-index: 2;
`

export default function PopupPanel() {
	const popupPanel = usePopupPanel()
	const [active, setActive] = useState(popupPanel.state.active)

	// Add a 10ms delay to fix bug.
	function onActiveChange() { setTimeout(() => setActive(popupPanel.state.active), 10) }
	useEffect(onActiveChange, [popupPanel.state.active])

	return (
		<SContainer active={popupPanel.state.active}>
			<SBackground onClick={() => popupPanel.setActive(false)} />
			<SPopupPanel active={active}>
				{popupPanel.state.wizardText && <Text center bold medium style={{ textAlign: 'right', marginRight: '35px', marginTop: '5px' }}>Save & Close</Text>}
				<SClose onClick={() => popupPanel.setActive(false)}>
					<SVG medium image={icons.cross} contain />
				</SClose>
				{popupPanel.state.content}
			</SPopupPanel>
		</SContainer>
	)
}
