export const firebaseConfig = {
    apiKey: "AIzaSyCghqAwhm22XV7SBncQ0Wf354PmOqC6N-0",
    authDomain: "alberta-32356.firebaseapp.com",
    databaseURL: "https://alberta-32356-default-rtdb.firebaseio.com/",
    projectId: "alberta-32356",
    storageBucket: "alberta-32356.appspot.com",
    messagingSenderId: "62165668097",
    appId: "1:62165668097:web:fceb8b908981b850210b00",
    measurementId: "G-52S6WSJCCZ",
};

export const storageUrl =
    "https://firebasestorage.googleapis.com/v0/b/alberta-32356.appspot.com/o/"; // Firebase Storage URL

export const cloudFunctions =
    "https://us-central1-alberta-32356.cloudfunctions.net/";

export const gitlab = {
    baseUrlGitLab: "https://gitlab.com/api/v4",
    accessTokenGitLab: "fmksgKNy3Pc6_XbBGiH_",
    idCustomScripts: 26802277,
    idMainRepo: 25364675,
    subModuleFolder: encodeURIComponent("Assets/albertaCustomScripts"),
    filePathVersion: encodeURIComponent(
        "ProjectSettings/ProjectSettings.asset"
    ),
};

export const urls = {
    googleStore:
        "https://play.google.com/console/u/2/developers/4971902527272514326/app/4972711980050430738/releases/overview",
    iosStore:
        "https://appstoreconnect.apple.com/apps/1550106482/appstore/ios/version/deliverable",
    getUnityViewerUrl: (id: string) =>
        `https://alberta-32356.web.app/?dbUrl=${firebaseConfig.databaseURL}&storageUrl=${storageUrl}&sceneId=${id}`,
};
