import styled, { css } from 'styled-components'
import {useStore } from '../../hooks'

import icons from '../../assets/icons'
import { Text, SVG } from '../../components/UI'

export default function StepFiveWizard() {
    const store = useStore()

    return (
            <>
                <SVG style={{ margin:'auto', height: '200px', width: '200px'}} contain image={icons.versionsCheck} primary/>

                <Text dark bold center big>Update successful</Text>
                <Text dark bold center style={{marginTop:'50px'}}>New Live Version</Text>
                <Text dark bold center style={{fontSize:'50px'}}>{store.state?.config?.testVersion.version}</Text>
                <Text medium center style={{maxWidth: "600px", margin: 'auto', marginTop: '20px'}}>
                    App users will now be required to update in stores to enforce all experiences work as expected with latest custom scripts.
                </Text>
            </>
    )
}

/*STYLES*/

