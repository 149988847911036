import React, { useReducer, useState, useEffect } from 'react'
import { getContent, subscribeToContent, setContent, Paths } from '../firebase/database'
import { key } from 'firebase-key'

import { IUser } from '../context/AuthContext'

export interface Dictionary<T> { [Key: string]: T }

export interface ICategory {
	id?: string,
	experiences?: Dictionary<number>,
	imageVersion?: number,
	name?: string,
	order?: number
}

export interface IConfig {
	minAppVersionAndroid: string,
	minAppVersionIOS: string,
	watermarkLink?: string,
	watermarkOn?: boolean,
	liveVersion: string,
	testVersion: {
		version: string,
		status: string,
		cloudBuildIdAndroid: string,
		cloudBuildIdIOS: string,
		errorMessage: string,
		experiencesToTest: any[],
		promotedToLiveAndroid: boolean,
		promotedToLiveIos: boolean,
		storeReviewAndroid: boolean,
		storeReviewIos: boolean,
	}
}

export interface IExperience {
	id?: string,
	description?: string,
	imageVersion?: number,
	name?: string,
	sceneId?: string,
	sceneIdTest?: string
}

export interface IScene {
	id?: string,
	bundleVersion?: number,
	displayName?: string,
	uploadTimestamp?: number,
	hasCustomScripts?: boolean,
	version?: number,
}

export interface IState {
	categories?: Dictionary<ICategory>,
	config?: IConfig,
	experiences?: Dictionary<IExperience>,
	scenes?: Dictionary<IScene>
}

export interface IStore {
	state?: IState,
	dispatch?(action: IAction): void
}

const Store = React.createContext<IStore>({})

// Handle dictionary object changes.
function dictionaryReducer(databasePath: string, dictionary: Dictionary<any>, action: IAction): Dictionary<any> {
	dictionary = dictionary ? { ...dictionary } : {}
	if (!action.payload.id) return dictionary

	if (action.type.includes('set') && action.payload.id) {
		dictionary[action.payload.id] = action.payload

		const firebasePayload = { ...action.payload }
		delete firebasePayload.id
		setContent(`${databasePath}/${action.payload.id}`, firebasePayload)
	}

	if (action.type.includes('delete') && action.payload.id) {
		delete dictionary[action.payload.id]
		setContent(`${databasePath}/${action.payload.id}`, {})
	}

	return dictionary
}

function objectReducer(databasePath: string, action: IAction): Dictionary<any> {
	if (action.type.includes('set')) {
		setContent(`${databasePath}`, action.payload)
	}

	if (action.type.includes('delete')) {
		setContent(`${databasePath}`, {})
	}

	return action.payload
}

interface IAction {
	type: 'init' | 'set-experience' | 'delete-experience' | 'set-category' | 'delete-category' | 'set-scene' | 'delete-scene' | 'set-config' | 'set-local'
	payload: any
}

function reducer(state: IState, action: IAction) {
	if (action.type === 'init') return action.payload

	if (action.type.includes('experience')) return { ...state, experiences: dictionaryReducer(Paths.experiences, state.experiences, action) }
	if (action.type.includes('category')) return { ...state, categories: dictionaryReducer(Paths.categories, state.categories, action) }
	if (action.type.includes('scene')) return { ...state, scenes: dictionaryReducer(Paths.scenes, state.scenes, action) }
	if (action.type.includes('config')) return { ...state, config: objectReducer(Paths.config, action) }

	if (action.type.includes('local')) return { ...state, local: {...action.payload} }

	return state
}

// Set ID field to each entry value using its key.
function setIds(dict: Dictionary<any>): Dictionary<any> {
	Object.entries(dict).forEach(([key, value]) => value.id = key)
	return dict
}

export function StoreContext(props) {
	const [state, dispatch] = useReducer(reducer, {})

	async function init(): Promise<void> {
		let categories = setIds(await getContent(Paths.categories))
		let config = await getContent(Paths.config)
		let experiences = setIds(await getContent(Paths.experiences))
		let scenes = setIds(await getContent(Paths.scenes))

		// Dispatch initial database to store's state.
		function set(): void { dispatch({ type: 'init', payload: { categories, config, experiences, scenes  } }) }

		// Update local state on firebase database changes.
		function subscribe(): void {
			subscribeToContent(Paths.categories, value => {
				categories = setIds(value)
				set()
			})
			subscribeToContent(Paths.config, value => {
				config = value
				set()
			})
			subscribeToContent(Paths.experiences, value => {
				experiences = setIds(value)
				set()
			})
			subscribeToContent(Paths.scenes, value => {
				scenes = setIds(value)
				set()
			})
		}

		subscribe()
		set()
	}

	useEffect(() => { init() }, [])

	return <Store.Provider value={{ state: state, dispatch: dispatch }}>{props.children}</Store.Provider>
}

export default Store