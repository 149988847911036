import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Loading from '../components/Loading'
import { Button, Text, InputField } from '../components/UI'

import { useAuth, useAwakeBool } from '../hooks'

import Logo from '../components/Logo'

const SLogin = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;

    background: linear-gradient(0deg, ${p => p.theme.colors.primary} 0%, ${p => p.theme.colors.alternative} 100%);
`

const SLogo = styled.div`
    height: 3.5rem;
`

const SPanelHolder = styled.div<{ active: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    transition: .3s;
    opacity: ${p => p.active ? 1 : 0};
    ${p => !p.active && css`
        transform: translate(-50%, -40%);
    `}
`

const SPanel = styled.div`
    overflow: hidden;
    position: relative;
    background-color: white;
    display: grid;
    padding: ${p => p.theme.padding};
    grid-gap: ${p => p.theme.gridGap};
    border-radius: ${p => p.theme.borderRadius};
`

const SLoading = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${p => p.theme.colors.light};
`

interface IState {
    email?: string,
    password?: string,
    loading: boolean
}

export default function Login() {
    const [state, set] = useState<IState>({
        loading: false
    })
    const auth = useAuth()
    const awake = useAwakeBool()

    function forgotPasswordHandler(): void {
        // Popup.
    }

    async function loginHandler(): Promise<void> {
        if (!state.email || !state.password) {
            return
        }

        set(p => ({ ...p, loading: true }))
        try { await auth.logIn(state.email, state.password) }
        catch {
            set(p => ({ ...p, loading: false }))
        }

        set(p => ({ ...p, loading: false }))
    }

    return (
        <SLogin>
            <SPanelHolder active={awake}>
                <SLogo> <Logo /> </SLogo>
                <SPanel>
                    <Text style={{ paddingLeft: '1.5rem' }} bold big dark>Welcome</Text>
                    <InputField
                        value={state.email}
                        onChange={v => set(prev => ({ ...prev, email: v }))}
                        placeholder='Email'
                        type='email'
                        onKeyDown={e => { if (e.keyCode === 13) loginHandler() }}
                    />
                    <InputField
                        value={state.password}
                        onChange={v => set(prev => ({ ...prev, password: v }))}
                        placeholder='Password'
                        type='password'
                        onKeyDown={e => { if (e.keyCode === 13) loginHandler() }}
                    />
                    <Text bold dark center onClick={() => forgotPasswordHandler()}>Forgot your password?</Text>
                    <div />
                    <Button onClick={() => loginHandler()}>Login</Button>

                    {state.loading && <SLoading><Loading /></SLoading>}
                </SPanel>
            </SPanelHolder>
        </SLogin>
    )
}
