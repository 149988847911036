import React, { useContext, useEffect, useState } from 'react'

import { getUrl, fileExists } from '../firebase/storage'

import Router, { IRouter } from '../context/RouterContext'
import Events, { IEvents } from '../context/EventsContext'
import PopupPanel, { IPopupPanel } from '../context/PopupPanelContext'
import Auth, { IAuth } from '../context/AuthContext'
import Wizard, { IWizard } from '../context/WizardContext'
import Store, { IStore } from '../store'
import { useHistory } from 'react-router-dom'

export function useStore() {
    const store = useContext(Store) as IStore
    return store
}

export function useEvents() {
    const events = useContext(Events) as IEvents
    return events
}

export function useRouter() {
    const router = useContext(Router) as IRouter
    return router
}

export function usePopupPanel() {
    const popupPanel = useContext(PopupPanel) as IPopupPanel
    return popupPanel
}

export function useAuth() {
    const auth = useContext(Auth) as IAuth
    return auth
}

export function useWizard() {
    const wizard = useContext(Wizard) as IWizard
    return wizard
}

export function useAwakeBool() {
    const [state, set] = useState(false)
    useEffect(() => { if (!state) set(true) }, [state])
    return state
}

export function useUrl(path) {
    const [url, setUrl] = useState()

    useEffect(() => {
        if (path) getUrl(path).then(setUrl)
    }, [path])

    return url
}

export function useFileExists(path: string, version: number = 1) {
    const [state, set] = useState<{ loading: boolean, value: boolean }>({
        loading: true,
        value: false
    })

    useEffect(() => {
        set({ loading: true, value: false })
        fileExists(path).then(v => set({ loading: false, value: v }))
    }, [path, version])

    return state
}