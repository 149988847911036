import { cloudFunctions } from "../brand";

type tTarget = "android" | "ios";

const buildRequest = (
    url: string,
    target: tTarget,
    version?: string
): Promise<any> =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`${cloudFunctions}${url}`, {
                method: "POST",
                body: JSON.stringify({
                    target,
                    version,
                }),
                headers: {
                    "Content-type": "application/json",
                },
            });

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

export const startBuild = (target: tTarget): Promise<any> =>
    buildRequest("startBuild", target);
export const cancelBuild = (target: tTarget): Promise<any> =>
    buildRequest("cancelBuild", target);
export const getBuild = (target: tTarget, buildId: string): Promise<any> =>
    buildRequest("getBuild", target, buildId);
