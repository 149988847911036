import React from 'react'
import styled from 'styled-components'

import { Text, SVG, Button } from '../components/UI'
import { usePopupPanel } from '../hooks'

const SConfirmation = styled.div`
    background-color: ${p => p.theme.colors.light};
    border-radius: ${p => p.theme.borderRadius};

    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

const SIcon = styled.div`
    width: 4rem;
    height: 4rem;
    margin: auto;
`

const SHorizontal = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-columns: 1fr 1fr;
`

export default function Confirmation(props:
    { title: string, icon: string, description: string, subDescription: string, cancel: string, confirm: string, confirmHandler(): void }) {
    const popupPanel = usePopupPanel()

    return (
        <SConfirmation>
            <Text center oneline bold dark>{props.title}</Text>
            <SIcon>
                <SVG image={props.icon} primary contain />
            </SIcon>
            <Text style={{ margin: '0 2rem' }} center oneline bold dark subTitle>{props.description}</Text>
            <Text center oneline bold medium>{props.subDescription}</Text>
            <div />
            <SHorizontal>
                <Button light onClick={() => popupPanel.setActive(false)}>{props.cancel}</Button>
                <Button onClick={() => {
                    props.confirmHandler()
                    popupPanel.setActive(false)
                }}>{props.confirm}</Button>
            </SHorizontal>
        </SConfirmation>
    )
}
