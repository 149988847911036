import addIcon from './add.svg'
import arrangeIcon from './arrange.svg'
import arrowIcon from './arrow.svg'
import categoriesIcon from './categories.svg'
import deleteIcon from './delete.svg'
import detailsIcon from './details.svg'
import editIcon from './edit.svg'
import experiencesIcon from './experiences.svg'
import filesIcon from './files.svg'
import logoutIcon from './logout.svg'
import searchIcon from './search.svg'
import shareIcon from './share.svg'
import viewIcon from './view.svg'
import replaceIcon from './replace.svg'
import uploadIcon from './upload.svg'
import crossIcon from './cross.svg'
import warningIcon from './warning.svg'
import historyIcon from './history.svg'
import arrowDownIcon from './arrow_down.svg'
import arrowUpIcon from './arrow_up.svg'
import folderIcon from './folder.svg'
import greenPointIcon from './green_point.svg'
import attachIcon from './attach.svg'
import versionsIcon from './versions.svg'
import versionsNewIcon from './version_new.svg'
import cloudIcon from './cloud.svg'
import listCheckIcon from './list_check.svg'
import versionsCheckIcon from './version_check.svg'
import windowUPIcon from './window_up.svg'
import arrowRightIcon from './arrow_right.svg'
import checkIcon from './check.svg'
import androidIcon from './android.svg'
import iosIcon from './ios.svg'
import warningRIcon from './warning_r.svg'
import checkBoxIcon from './check_box.svg'
import checkBoxEmptyIcon from './check_box_empty.svg'
import versionProgressIcon from './version_progress.svg'
import versionWarningIcon from './version_warning.svg'

const icons = {
    add: addIcon,
    arrange: arrangeIcon,
    arrow: arrowIcon,
    categories: categoriesIcon,
    delete: deleteIcon,
    details: detailsIcon,
    edit: editIcon,
    experiences: experiencesIcon,
    files: filesIcon,
    logout: logoutIcon,
    search: searchIcon,
    share: shareIcon,
    view: viewIcon,
    replace: replaceIcon,
    upload: uploadIcon,
    cross: crossIcon,
    warning: warningIcon,
    history: historyIcon,
    arrowDown: arrowDownIcon,
    arrowUp: arrowUpIcon,
    folder: folderIcon,
    greenPoint: greenPointIcon,
    attach: attachIcon,
    versions: versionsIcon,
    versionNew: versionsNewIcon,
    cloud: cloudIcon,
    listCheck: listCheckIcon,
    versionsCheck: versionsCheckIcon,
    windowUP: windowUPIcon,
    arrowRight: arrowRightIcon,
    check: checkIcon,
    android: androidIcon,
    ios: iosIcon,
    warningR: warningRIcon,
    checkBox: checkBoxIcon,
    checkBoxEmpty: checkBoxEmptyIcon,
    versionProgress: versionProgressIcon,
    versionWarning: versionWarningIcon,
}

export default icons